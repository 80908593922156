import React, { createContext, useContext, useState, useEffect } from 'react'
import i18n from 'i18n-js'
import {FlatList, Menu, Box, Text, Button} from 'native-base'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { TouchableOpacity } from "react-native"
import {I18n} from "aws-amplify";

interface LanguageContextType {
  language: string
  changeLanguage: (newLanguage: string) => void
}

export const LanguageContext = createContext<LanguageContextType | null>(null)

export const LanguageProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState<string>(i18n.locale)

  useEffect(() => {
    const loadLanguage = async () => {
      const savedLanguage = await AsyncStorage.getItem('language')
      if (savedLanguage) {
        i18n.locale = savedLanguage
        I18n.setLanguage(savedLanguage)
        setLanguage(savedLanguage)
      }
    }
    loadLanguage()
  }, [])

  const changeLanguage = async (newLanguage: string) => {
    i18n.locale = newLanguage
    I18n.setLanguage(newLanguage)
    setLanguage(newLanguage)
    await AsyncStorage.setItem('language', newLanguage)
  }

  return (
      <LanguageContext.Provider value={{ language, changeLanguage }}>
        {children}
      </LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}

export function LanguagePicker() {
  const { language, changeLanguage } = useLanguage()
  const languages = Object.entries(i18n.t('languages'))

  const englishTranslations = {
    'Sign in': 'Sign in',
    'Sign out': 'Sign out',
    'Forgot your password?': 'Forgot your password?',
    'Username': 'Username',
    'Password': 'Password',
    'Sign In': 'Sign In',
    'Sign Out': 'Sign Out',
    'Sign Up': 'Sign Up',
    'Reset your password': 'Reset your password',
    'Confirm password': 'Confirm password',
    'Confirm Sign Up': 'Confirm Sign Up',
    'Resend Code': 'Resend Code',
    'Back to Sign In': 'Back to Sign In',
    'Change Password': 'Change Password',
    'Code': 'Code',
    'Submit': 'Submit',
    'Verify': 'Verify',
    'Enter your username': 'Enter your username',
    'Send code': 'Send Code',
    'User does not exist.': 'User does not exist'
  };


  const frenchTranslations = {
    'Sign in': 'Se connecter',
    'Sign out': 'Se déconnecter',
    'Forgot your password?': 'Mot de passe oublié ?',
    'Username': 'Nom d’utilisateur',
    'Password': 'Mot de passe',
    'Sign In': 'Se connecter',
    'Sign Out': 'Se déconnecter',
    'Sign Up': 'S’inscrire',
    'Reset your password': 'Réinitialiser votre mot de passe',
    'Confirm password': 'Confirmer le mot de passe',
    'Confirm Sign Up': 'Confirmer l’inscription',
    'Resend Code': 'Renvoyer le code',
    'Back to Sign In': 'Retour à la connexion',
    'Change Password': 'Changer le mot de passe',
    'Code': 'Code',
    'Submit': 'Soumettre',
    'Verify': 'Vérifier',
    'Enter your username': 'Entrez votre nom d’utilisateur',
    'Send code': 'Envoyer le code',
    'User does not exist.': 'L’utilisateur n’existe pas'
  };


  I18n.putVocabulariesForLanguage('en', englishTranslations);
  I18n.putVocabulariesForLanguage('fr', frenchTranslations);

  return (
      <Menu
          closeOnSelect={false}
          w="200"
          placement="bottom"
          trigger={(triggerProps: any) => (
              <Button {...triggerProps} style={style.langText}>{language === 'en-US' ? 'en' : language}</Button>
          )}
      >
        <Menu.Group title={'Languages'}>
          <FlatList
              data={languages}
              keyExtractor={([key]) => key}
              renderItem={({ item: [key, label] }) => (
                  <TouchableOpacity
                      key={key}
                      onPress={() => changeLanguage(key)}
                      style={language === key ? style.buttonActive : style.button}
                  >
                    <Box flexDirection="row" alignItems="center">
                      <Text>{label}</Text>
                    </Box>
                  </TouchableOpacity>
              )}
          />
        </Menu.Group>
      </Menu>
  )
}

const style = {
  button: {
    backgroundColor: 'transparent',
    padding: 10,
  },
  buttonActive: {
    backgroundColor: '#BBDEFB',
    padding: 10,
  },
  langText: {
    textTransform: 'uppercase' as 'uppercase',
  }
}
