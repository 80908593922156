import { MaterialIcons } from '@expo/vector-icons'
import BigTileButton from 'components/BigTileButton'
import DashboardLayout from 'components/DashboardLayout'
import { useLanguage } from 'components/LanguagePicker'
import { t } from 'i18n-js'
import { Center, FlatList, VStack, useBreakpointValue } from 'native-base'
import React from 'react'
import { RootStackScreenProps } from 'utils/formDesigner/navigation'
import { useUser } from 'utils/store'
import {Platform} from "react-native";

const options = [
  {
    icon: <MaterialIcons name="add-box" />,
    label: 'add-user',
    to: 'EditUser',
  },
  {
    icon: <MaterialIcons name="search" />,
    label: 'find-user',
    to: 'FindUser',
  },
  {
    icon: <MaterialIcons name="add-location" />,
    label: 'add-location',
    to: 'EditLocation',
  },
  {
    icon: <MaterialIcons name="map" />,
    label: 'find-location',
    to: 'FindLocation',
  },
  {
    icon: <MaterialIcons name="settings" />,
    label: 'settings',
    to: 'Settings',
  },
  {
    icon: <MaterialIcons name="school" />,
    label: 'training',
    to: 'Training',
  },
  {
    icon: <MaterialIcons name="email" />,
    label: 'emails',
    to: 'Emails',
  },
  {
    icon: <MaterialIcons name="email" />,
    label: 'add-email',
    to: 'EditEmail',
  },
]

export default function ({ route, navigation }: RootStackScreenProps<'Home'>) {
  const shape = useBreakpointValue({
    base: { columns: 2, w: '160px', h: '160px', size: 8, fontSize: 'md' },
    sm: { columns: 2, w: '190px', h: '190px', size: 16, fontSize: 'lg' },
    md: {
      columns: Platform.OS === 'web' ? 3 : 2,
      w: '200px',
      h: '200px',
      size: 16,
      fontSize: 'lg',
    },
  })

  const [user] = useUser()
  const { language } = useLanguage()
  if (!user || !user.attributes) return <></>
  return (
    <DashboardLayout
      title={t('other.welcome') + ' ' + (user ? user.attributes.nickname : '')}
      displaySidebar={false}
      displayScreenTitle={false}
      backButton={false}
      navigation={navigation}
      showLogos
    >
      <VStack
        safeAreaBottom
        height="90%"
        borderRadius={{ md: '8' }}
        borderColor="coolGray.200"
        bg="white"
        px={{
          base: 4,
          md: 32,
        }}
      >
        <Center pt="5">
          <FlatList
            numColumns={shape.columns}
            data={options}
            renderItem={({ item }) => (
              <BigTileButton
                icon={item.icon}
                label={t(`home.${item.label}`)}
                navigation={navigation}
                route={route}
                navigateTo={item.to}
                w={shape.w}
                h={shape.h}
                size={shape.size}
                fontSize={shape.fontSize}
              />
            )}
            key={shape.columns}
            keyExtractor={item => item.to}
          />
        </Center>
      </VStack>
    </DashboardLayout>
  )
}
