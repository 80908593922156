import React from 'react'
import DashboardLayout from 'components/DashboardLayout'
import { t } from 'i18n-js'
import EmailSearch from "../../components/EmailSearch";

export default function FindEmails({ route, navigation }: any) {
  return (
      <DashboardLayout
          navigation={navigation}
          displaySidebar={false}
          displayScreenTitle={false}
          title={t('home.emails')}
      >
        <EmailSearch
            selectItem={(email, setRefresh) => {
              navigation.navigate('EditEmail', {
                ...route.params,
                email,
                setRefresh,
              })
            }}
        />
      </DashboardLayout>
  )
}
