import {AntDesign, MaterialIcons} from '@expo/vector-icons'
import {
    Box, Button, Center,
    HStack,
    Icon,
    Pressable,
    ScrollView, Select,
    Text,
    VStack,
} from 'native-base'
import React from 'react'
import { t } from 'i18n-js'
import {EmailType} from "../utils/types/email";
import DebouncedTextInput from "./form-parts/DebouncedTextInput";

export function ListItem({ item }: { item: EmailType }) {
  return (
    <Pressable p={2} borderBottomWidth={0.8} borderBottomColor="coolGray.300">
      <HStack justifyContent="space-between" w="100%">
        <HStack alignItems="center" space={4} w="55%">
          <VStack>
            <Text
              isTruncated
              bold
              fontSize="sm"
              noOfLines={2}
              maxW="64"
              color="coolGray.900"
            >
              {item.name}
            </Text>
          </VStack>
        </HStack>
        <HStack alignItems="center" space={4} w="32%">
          <VStack>
            <Text isTruncated maxW="100%" fontSize="xs" color="coolGray.900">
              {item.email}
            </Text>
            <Text isTruncated fontSize="xs" maxW="60%" color="coolGray.500">
              {item.enabled}
            </Text>
          </VStack>
        </HStack>
        <HStack w="5%">
          {item.enabled ? (
            <Icon
              color="success.400"
              size="6"
              name="check-circle"
              as={MaterialIcons}
            />
          ) : (
            <Icon color="error.700" size="6" name="cancel" as={MaterialIcons} />
          )}
        </HStack>
      </HStack>
    </Pressable>
  )
}

export function ListItemDesktop({
  item,
  selectItem,
}: {
  item: EmailType
  selectItem: (email: EmailType) => any
}) {
  return (
    <Pressable
      px={2}
      flex={1}
      _hover={{ bg: 'coolGray.100' }}
      onPress={() => selectItem(item)}
    >
        <HStack
            alignItems="center"
            justifyContent="space-between"
            height={10}
        >
            <VStack  w="10%">
              <Text bold>
                {item.name}
              </Text>
            </VStack>
            <VStack  w="23%">
              <Text ml={2}>
                {item.email}
              </Text>
            </VStack>
            <VStack w="23%">
                <Text>{item.address}</Text>
            </VStack>
            <VStack  w="10%">
              {item.enabled ? (
                 <Icon
                    color="success.400"
                    size="6"
                    name="check-circle"
                    as={MaterialIcons}
                 />
              ) : (
                   <Icon color="error.700" size="6" name="cancel" as={MaterialIcons} />
              )}
            </VStack>
            <VStack  w="10%">
              {item.enabled ? (
                <Icon
                  color="success.400"
                  size="6"
                  name="check-circle"
                  as={MaterialIcons}
                />
              ) : (
                <Icon color="error.700" size="6" name="cancel" as={MaterialIcons} />
              )}
            </VStack>
      </HStack>
    </Pressable>
  )
}

interface Props {
  hasMore: boolean;
  emails: EmailType[];
  loadMore?: () => any
  filterVerificationStatus: string
  filterEnabled: string
  setFilterVerificationStatus: (e: string) => void
  setFilterEnabled: (e: string) => void
  filterSearchType: string
  setFilterSearchType: React.Dispatch<React.SetStateAction<string>>
  filterText: string | undefined
  setFilterText: React.Dispatch<React.SetStateAction<string | undefined>>
  selectItem: (email: EmailType) => any
  doSearch: () => any
}
export const EmailList:React.FC<Props> = ({
   hasMore = false,
   emails,
   loadMore,
   filterVerificationStatus,
   filterEnabled,
   setFilterVerificationStatus,
   setFilterEnabled,
   filterSearchType,
   setFilterSearchType,
   filterText,
   setFilterText,
   selectItem,
   doSearch,
}) => {
  return (
      <>
          <HStack bg={'muted.50'} flexDirection={'column'} p={5} borderRadius={10}>
              <HStack flexDirection={'row'} justifyContent={'center'}>
                  <HStack>
                      <Center>
                          <Select
                              size="md"
                              bg="white"
                              selectedValue={filterEnabled}
                              onValueChange={setFilterEnabled}
                              placeholder={t('email.filter.select-email-enabled')}
                              ml={{ base: 0, md: 2 }}
                          >
                              <Select.Item
                                  key={'__any__'}
                                  label={t('email.filter.any-is-email-enabled')}
                                  value={''}
                              />
                              {['enabled', 'disabled'].map(e => (
                                  <Select.Item key={e} label={t('email.filter.' + e)} value={e} />
                              ))}
                          </Select>
                      </Center>
                      <Center>
                          <Select
                              size="md"
                              bg="white"
                              selectedValue={filterVerificationStatus}
                              onValueChange={setFilterVerificationStatus}
                              placeholder={t('email.filter.select-email-verfication')}
                              ml={{ base: 0, md: 2 }}
                          >
                              <Select.Item
                                  key={'__any__'}
                                  label={t('email.filter.any-email-verfication')}
                                  value={''}
                              />
                              {['verified', 'not verified'].map(e => (
                                  <Select.Item key={e} label={t('email.filter.' + e)} value={e} />
                              ))}
                          </Select>
                      </Center>
                  </HStack>
                  <HStack
                      my={{ md: 0, base: 2 }}
                      mb={{ md: 1, base: 2 }}
                      justifyContent="center"
                  >
                      <Button
                          onPress={() => {
                              setFilterSearchType('')
                              setFilterText('')
                              setFilterVerificationStatus('')
                              setFilterEnabled('')
                          }}
                          leftIcon={<Icon as={MaterialIcons} name="close" />}
                          size="sm"
                          ml={4}
                          mr={2}
                      />
                      <Button
                          onPress={doSearch}
                          leftIcon={<Icon as={MaterialIcons} name="refresh" />}
                          size="sm"
                      />
                  </HStack>
              </HStack>
              <HStack py={2} w="100%" justifyContent="center" flexDirection={{md: 'row', sm: 'column'}} alignItems={{md:'center', sm: 'center'}}>
                  <Center  w={{ md: '25%', lg: '25%', base: '25%' }}>
                      <Select
                          size="md"
                          bg="white"
                          selectedValue={filterSearchType}
                          onValueChange={setFilterSearchType}
                          placeholder={t('user.search-by.select')}
                          overflowX={'hidden'}
                          overflowY={'hidden'}
                          ml={{ base: 0, md: 2 }}
                          mb={{sm: 3, md: 0}}
                          w={{ md: '100%', lg: '100%', base: '100%' }}
                      >
                          {['address', 'email', 'name'].map(
                              e => (
                                  <Select.Item
                                      key={e}
                                      label={t('user.search-by.' + e)}
                                      value={e}
                                  />
                              )
                          )}
                      </Select>
                  </Center>
                  <DebouncedTextInput
                      flex={{ md: undefined, lg: undefined, base: 1 }}
                      w={{ md: '70%', lg: '70%', base: '70%' }}
                      py={3}
                      mx={{ base: 4, md: 0 }}
                      mr={{ base: 4, md: 4, lg: 5, xl: 10 }}
                      bg="white"
                      InputLeftElement={
                          <Icon
                              as={<AntDesign name="search1" />}
                              size={{ base: '4', md: '4' }}
                              my={2}
                              ml={2}
                              _light={{
                                  color: 'coolGray.400',
                              }}
                          />
                      }
                      size="lg"
                      color="black"
                      placeholder={t('user.search-enter')}
                      debounceMs={1000}
                      value={filterText}
                      overflowX={'hidden'}
                      overflowY={'hidden'}
                      onChangeText={setFilterText}
                  />
              </HStack>
          </HStack>
          <VStack
              px={{ base: 4, md: 8 }}
              py={{ base: 2, md: 8 }}
              borderRadius={{ md: '8' }}
              _light={{
                  borderColor: 'coolGray.200',
                  bg: { base: 'white' },
              }}
              borderWidth={{ md: '1' }}
              borderBottomWidth="1"
              space="4"
          >
              <Box>
                  <ScrollView>
                      <Box position="relative" display={{ md: 'none', base: 'flex' }}>
                          {emails.map((item: EmailType, index: number) => {
                              return <ListItem item={item} key={index} />
                          })}
                      </Box>
                      <Box display={{ md: 'flex', base: 'none' }}>
                          <HStack
                              alignItems="center"
                              justifyContent="space-between"
                              borderBottomWidth={1}
                              _light={{ borderColor: 'coolGray.200' }}
                          >
                              <Text
                                  fontWeight="bold"
                                  textAlign="left"
                                  w="20%"
                                  ml={1}
                                  mb={3}
                                  _light={{ color: 'coolGray.800' }}
                              >
                                  {t('heading.name')}
                              </Text>
                              <Text
                                  fontWeight="bold"
                                  textAlign="left"
                                  w="23%"
                                  mb={3}
                                  _light={{ color: 'coolGray.900' }}
                              >
                                  {t('email.email')}
                              </Text>
                              <Text
                                  fontWeight="bold"
                                  textAlign="left"
                                  w="23%"
                                  mb={3}
                                  _light={{ color: 'coolGray.900' }}
                              >
                                  {t('email.address')}
                              </Text>
                              <Text
                                  fontWeight="bold"
                                  textAlign="left"
                                  w="20%"
                                  mb={3}
                                  _light={{ color: 'coolGray.900' }}
                              >
                                  {t('email.verificationStatus')}
                              </Text>
                              <Text
                                  fontWeight="bold"
                                  textAlign="left"
                                  w="10%"
                                  mb={3}
                                  _light={{ color: 'coolGray.900' }}
                                  mr={-1}
                              >
                                  {t('heading.enabled')}
                              </Text>
                          </HStack>
                          <VStack mt={3} space={3}>
                              {emails.map((item: EmailType, index: number) => {
                                  return (
                                      <ListItemDesktop
                                          item={item}
                                          key={index}
                                          selectItem={selectItem}
                                      />
                                  )
                              })}
                          </VStack>
                      </Box>
                  </ScrollView>
              </Box>
          </VStack>
      </>
  )
}
