import { findUsers } from 'api/common'
import Loading from 'components/Loading'
import { t } from 'i18n-js'
import React, { useEffect, useState } from 'react'
import { handleStandardErrors, useInfo } from 'utils/errors'
import { UserType } from 'utils/types/user'
import { useLanguage } from './LanguagePicker'
import {findLocations} from "../api/manager";
import {LocationType} from "../utils/types/location";
import {UserList} from "./UserList";

export default function UserSearch({
  selectItem,
  defaultUserType = 'Manager',
  allowedUserTypes,
  onlyEnabledUsers = true,
  defaultLocationUUID = '',
}: {
  selectItem: (
    u: UserType,
    setRefresh: React.Dispatch<React.SetStateAction<Date>>
  ) => any
  defaultUserType?: string
  allowedUserTypes?: string[]
  onlyEnabledUsers?: boolean
  defaultLocationUUID?: string
}) {
  const [users, setUsers] = useState([] as UserType[])
  const [nextKey, setNextKey] = useState(undefined as any)
  const [filterUserType, setFilterUserType] = useState(defaultUserType)
  const [filterEnabledOrDisabled, setFilterEnabledOrDisabled] = useState('')
  const [filterLocation, setFilterLocation] = useState(defaultLocationUUID)
  const [filterSearchType, setFilterSearchType] = useState('')
  const [filterText, setFilterText] = useState(undefined as undefined | string)
  const [error, warning, success] = useInfo()
  const [waiting, setWaiting] = useState(null as null | string)
  const [locations,setLocations] = useState<LocationType[]>([])

  const [refresh, setRefresh] = useState(new Date())
  const { language } = useLanguage()

  const doSearch = async () => {
    findUsers(
      () => setWaiting(t('alert.searching')),
      () => setWaiting(null),
      filterEnabledOrDisabled,
      filterLocation,
      filterSearchType,
      filterText,
      filterUserType,
      e => handleStandardErrors(error, warning, success, e),
      setUsers,
      setNextKey
    )
  }

  useEffect(() => {
    doSearch()
  }, [
    filterUserType,
    filterEnabledOrDisabled,
    filterLocation,
    filterText,
    refresh,
  ])

  const doSearchLocations = async () => {
    findLocations(
        () => setWaiting(t('alert.loading')),
        () => setWaiting(null),
        '',
        '',
        '',
        '',
        'enabled',
        e => handleStandardErrors(error, warning, success, e),
        setLocations,
        setNextKey
    )
  }

  useEffect(() => {
    doSearchLocations()
  }, [])

  return (
    <>
      <UserList
        hasMore={false}
        users={users}
        locations={locations}
        filterUserType={filterUserType}
        setFilterUserType={setFilterUserType}
        filterEnabledOrDisabled={filterEnabledOrDisabled}
        setFilterEnabledOrDisabled={setFilterEnabledOrDisabled}
        filterLocation={filterLocation}
        setFilterLocation={setFilterLocation}
        filterSearchType={filterSearchType}
        setFilterSearchType={setFilterSearchType}
        filterText={filterText}
        setFilterText={setFilterText}
        doSearch={doSearch}
        defaultUserType={defaultUserType}
        allowedUserTypes={allowedUserTypes}
        selectItem={user => selectItem(user, setRefresh)}
      />
      <Loading loading={waiting} />
    </>
  )
}
