export default {
  languages: {
    en: 'Anglais',
    fr: 'Français',
  },
  gender: {
    male: 'Homme',
    female: 'Femme',
    intersex: 'Intersexe',
    transgender: 'Transgenre',
  },
  sex: {
    male: 'Homme',
    female: 'Femme',
    intersex: 'Intersexe',
    transgender: 'Transgenre',
  },
  tag: { 'sexual-assault': 'agression sexuelle' },
  heading: {
    name: 'Nom',
    countryAndLanguage: 'Pays & Langue',
    lastChangedAndId: 'Dernière modification & ID',
    lastChangedAndFormId: 'Dernière modification & Formulaire ID',
    type: 'Type',
    enabled: 'Activé',
    usernameAndEmail: 'Activé',
  },
  tabsFormEditor: {
    placeholder: 'Sélectionner une page',
    overview: 'Aperçu',
    files: 'Fichiers',
    associations: 'Associations',
    editor: 'Éditeur',
    printPreview: 'Aperçu avant impression',
    versions: 'Versions',
  },
  successfulCase:{
    'Form updated': 'Formulaire mis à jour',
    'Addendum updated': 'Addendum mis à jour',
    "Location updated": "Emplacement mis à jour",
    "Location created": "Emplacement créé"
  },
  signIn:{
    logInAs: 'Se connecter en tant que',
    username: 'Nom d\'utilisateur',
    password: 'Mot de passe',
    signInText: 'Se connecter',
    forgotPass: 'Mot de passe oublié ?',
    askHelp: 'Demandez de l\'aide à help@medicapt.click',
  },
  user: {
    'Healthcare provider': 'Fournisseur de soins de santé',
    'Provider': 'Fournisseur de soins de santé',
    Associate: 'Associé',
    Manager: 'Gestionnaire',
    'Form designer': 'Concepteur de formulaire',
    'FormDesigner': 'Concepteur de formulaire',
    Researcher: 'Chercheur',
    'select-user-type': 'Sélectionner le type d’utilisateur',
    'any-user-type': 'Tout type d’utilisateur',
    'enter-location': 'Restreindre par localisation',
    'any-location': 'N’importe quelle localisation',
    'search-by': {
      username: 'Rechercher par nom d’utilisateur',
      email: 'Rechercher par e-mail',
      'phone-number': 'Rechercher par # de téléphone',
      name: 'Rechercher par nom',
      'first-name': 'Rechercher par prénom',
      'family-name': 'Rechercher par nom de famille',
      'user-id-code': 'Rechercher par code ID utilisateur',
      select: 'Sélectionner le type de recherche',
    },
    'Male': 'Homme',
    'Female': 'Femme',
    'Transgender': 'Transgenre',
    filter: {
      'select-user-enabled': 'Utilisateurs activés ou désactivés',
      'any-is-user-enabled': 'Tous les utilisateurs',
      enabled: 'Utilisateurs activés',
      disabled: 'Utilisateurs désactivés',
    },
    'search-enter': 'Entrer le texte de recherche',
    'full-official-name': 'Nom officiel complet',
    'short-name': 'Nom court',
    nickname: 'Surnom',
    email: 'Adresse e-mail',
    'phone-number': 'Numéro de téléphone',
    'phone-number-with-help': 'Numéro de téléphone comme (+12223334444)',
    username: 'Nom d’utilisateur',
    address: 'Adresse',
    'user-type': 'Type d’utilisateur',
    heading: {
      'official-id': 'ID officiel',
      metadata: 'Métadonnées',
      bio: 'Bio',
    },
    'create-user': 'Créer un utilisateur',
    'disable-user': 'Désactiver l’utilisateur',
    'enable-user': 'Activer l’utilisateur',
    'submitting-user': 'Mise à jour de l’utilisateur en cours',
    'submit-user': 'Mettre à jour l’utilisateur',
    'submitted-user': 'Utilisateur mis à jour',
    'create-email': 'Créer un e-mail',
    'creating-email': 'Création de l’e-mail',
    'submitting-email': 'Mise à jour de l’e-mail',
    'submit-email': 'Mettre à jour',
    'reset-password': 'Réinitialiser le mot de passe',
    'password-reset': 'Mot de passe utilisateur réinitialisé',
    'password-resetting': 'Réinitialisation du mot de passe en cours',
    'email-confirmed': 'E-mail de l’utilisateur confirmé',
    'confirming-email': 'Confirmation de l’e-mail en cours',
    'confirm-email': 'Confirmer l’adresse e-mail',
    official_id_type: 'Type d’ID officiel',
    official_id_code: 'Code ID officiel',
    official_id_expires: 'Date d’expiration de l’ID',
    id: 'Code ID utilisateur',
    'last-changed': 'Dernière modification',
    'created-on': 'Créé le',
    'allowed-locations': 'Localisations autorisées',
    birthday: 'Anniversaire',
    'date-of-birth': 'Anniversaire',
    gender: 'Genre',
    'expiry-date': 'Date d’expiration de l’utilisateur',
    search: {
      'form-names-and-tags': 'Rechercher des noms de formulaires et des tags',
    },
    'resend-confirmation-email': 'Renvoyer l’e-mail de confirmation',
    'resending-confirmation-email':
      'Renvoi de l’e-mail de confirmation en cours',
    'resent-confirmation-email': 'E-mail de confirmation renvoyé',
    "Oui": "Oui",
    "Non": "Non",
    "userEnabled": "L'utilisateur est activé",
    "userDisabled": "L'utilisateur est désactivé"
  },
  common: {
    'read-only': 'lecture seule',
    'automatically-created': 'créé automatiquement',
    'space-separated-location-ids':
      'IDs de localisation séparés par des espaces',
    'cannot-be-changed': 'ne peut pas être modifié',
  },
  addendum: {
    'addendum-name': 'Nom de l’addendum',
    type: 'Type',
    data: 'Données',
    'create-addendum': 'Créer un addendum',
    'submit-addendum': 'Soumettre un addendum',
    'delete-addendum': 'Supprimer un addendum',
    'select-addendum': 'Sélectionner un addendum',
    'select-type': 'Sélectionner le type',
    'any-type': 'Tout type',
    search: 'Rechercher',
    types: {
      type_1: 'faux type 1',
      type_2: 'faux type 2',
    },
    'not-filled': 'N/A',
    filter: {
      'select-addendum-enabled': 'Formulaires activés ou désactivés',
      'any-is-addendum-enabled': 'Tous les formulaires',
      enabled: 'Formulaires activés',
      disabled: 'Formulaires désactivés',
    },
    label: {
      'short-name': 'Nom court',
      coordinates: 'Coordonnées',
      'tag-optional': 'Tag (optionnel)',
      record: 'Enregistrer',
      addendum: 'Addendum',
      dates: 'Dates',
    },
    'addendum-list': {
      title: 'Titre',
      'tags-or-addendum-ID': 'Tags / Addendum ID',
      'date-changed': 'Date modifiée',
      enabled: 'Activé',
    },
    Yes: 'Oui',
    No: 'Non',
    'user-editor': 'Éditeur d’utilisateur',
    'addendum-editor': 'Éditeur d’addendum',
    'location-editor': 'Éditeur de localisation',
    'fill-record': 'Remplir un enregistrement',
    'select-value': 'Sélectionner une valeur',
    'other-details': 'Décrire d’autres détails optionnels',
    'details-about-other': 'Détails sur autre (optionnel)',
    'add-photo': 'Ajouter une photo existante',
    'take-photo': 'Prendre une nouvelle photo',
    'save-photo': 'Enregistrer',
    'cancel-photo': 'Annuler',
    'delete-photo': 'Supprimer la photo',
    'mark-diagram': 'Annoter le diagramme',
    'repeated-one-time-below': 'répété une fois ci-dessous',
    'can-repeat-below': 'peut répéter ci-dessous',
    repeated: 'répété',
    letter: 'Lettre',
    section: 'Section',
    'times-below': 'fois ci-dessous',
    'repeat-number': 'nombre',
    'repeat-out-of': 'sur',
    'enter-date-time': 'Entrer la date et l’heure',
    'enter-date': 'Entrer la date',
    'enter-number': 'Entrer le nombre',
    'enter-phone-number': 'Entrer le numéro de téléphone',
    'enter-email': 'Entrer l’e-mail',
    'enter-text': 'Entrer le texte',
    'enter-long-text': 'Entrer le texte long',
    'enter-address': 'Entrer l’adresse',
    'select-other-option': 'Sélectionner une autre option',
    'select-the-option': 'Sélectionner l’option',
    'skip-question': 'Passer cette question',
    'camera-viewer': 'Vue de la caméra',
    'addendum-is-disabled': 'Addendum est désactivé',
    'addendum-is-enabled': 'Addendum est activé',
    'creating-addendum': 'Création de l’addendum',
    'addendum-created': 'Addendum créé',
    'not-yet-created': 'Pas encore créé',
    'addendum-checklist': 'LISTE DE CONTRÔLE DU FORMULAIRE',
    'no-addendum-body-filled-out': 'Aucun corps d’addendum rempli',
    'addendum-body-exists': 'Le corps de l’addendum existe',
    'help-body-fill':
      'Cliquez sur l’onglet éditeur et remplissez le corps de l’addendum',
    'addendum-has-paths-periods': 'L’addendum a des chemins avec des périodes',
    'from-paths-have-no-periods': 'Les chemins n’ont pas de périodes',
    'paths-with-periods': 'Chemins avec des périodes',
    'help-path-periods':
      'Votre addendum a un chemin avec une période. Tout texte dans un addendum qui vient avant un deux-points fait partie d’un chemin et ne peut pas contenir de période. Les chemins suivants ont des périodes.',
    'from-has-duplicate-paths': 'Le formulaire a des chemins en double',
    'addendum-paths-are-unique': 'Les chemins de l’addendum sont uniques',
    'dupicate-addendum-paths': 'Chemins d’addendum en double',
    'help-path-dublicate':
      'Votre addendum a des chemins en double dans la définition. Vous devez renommer au moins un de ces chemins.',
    'no-PDF-uploaded': 'Aucun PDF téléchargé',
    'PDF-uploaded': 'PDF téléchargé',
    'help-no-PDF-uploaded':
      'Sans un addendum PDF à remplir, nous générerons toujours un PDF avec un formatage automatique',
    'PDF-not-filled-out': 'Certaines parties du PDF ne sont pas remplies',
    'PDF-covered': 'Tous les champs PDF sont couverts',
    'help-PDF-not-filled-out': 'À FAIRE : implémenter ceci',
    'how-get-GPS-coordinates': 'Comment obtenir les coordonnées GPS',
    'location-is-disabled': 'La localisation est désactivée',
    'location-is-enabled': 'La localisation est activée',
    'created-on': 'Créé le (automatique)',
    'location-version': 'Version de la localisation (automatique)',
    'last-changed-on': 'Dernière modification le (automatique)',
    'selected-addendum': 'Addendum sélectionné',
  },
  versions: {
    title: "Navigateur d'historique de formulaire",
    latestVersion: "Dernière version",
    selectAVersion: "Sélectionnez une version du formulaire à charger",
    alertCannot: "Vous ne pouvez pas apporter de modifications, mais vous pouvez parcourir le formulaire. Quittez le formulaire et revenez pour réinitialiser.",
    historyMode: "Mode historique",
    showingVersion: "Affichage de la version",
    createdOn: "créé le",
    makeLatest: "Faire de cette version la dernière",
    alerts: {
      one: "Revenir à une version précédente créera une copie de cette version précédente. Maintenant, le formulaire est à la version ",
      two: ". Nous allons revenir à la version ",
      three: " en créant une nouvelle version, ",
      four: " qui est une copie de ",
      five: ". Êtes-vous sûr de vouloir faire cela ?"
    },
    loading: "Chargement du formulaire",
    error1: "Impossible de charger la version du formulaire ",
    error2: "Ce formulaire n'a pas encore été enregistré sur le serveur"
  },
  files: {
    downloadAllFiles: 'Télécharger tous les fichiers',
    tellMore:'Dites-moi en plus',
    annotatingPdfs: 'Annotation des PDFs',
    instruction: 'TODO Instructions sur ce qu`il faut télécharger et comment annoter',
    describe: 'TODO Décrire comment annoter un PDF dans Acrobat et comment le remplir avec des X',
    images: 'IMAGES',
    uploadImage: 'Télécharger une image',
  },
  location: {
    'full-official-name': 'Nom officiel complet de la localisation',
    address: 'Adresse',
    'mailing-address': 'Adresse postale',
    id: 'ID de la localisation',
    'automatically-created': 'créé automatiquement',
    'entity-type': 'Type d’entité',
    'create-location': 'Créer une localisation',
    'submit-location': 'Soumettre les modifications',
    'delete-location': 'Supprimer la localisation',
    'enable-location': 'Activer la localisation',
    'disable-location': 'Désactiver la localisation',
    'select-country': 'Sélectionner le pays',
    'any-country': 'N’importe quel pays',
    'select-language': 'Sélectionner la langue',
    'any-language': 'N’importe quelle langue',
    'select-entity-type': 'Sélectionner le type d’entité',
    'any-entity-type': 'N’importe quel type d’entité',
    'select-default-language': 'Sélectionner la langue par défaut',
    'any-default-language': 'N’importe quelle langue par défaut',
    search: 'Rechercher l’ID de la localisation comme ML3-XXX-YYY-ZZZ',
    edit:{
      title: 'Lieux autorisés',
    },
    entity: {
      'medical-facility': 'Établissement médical',
      'police-station': 'Poste de police',
      'refugee-camp': 'Camp de réfugiés',
    },
    'coordinates-instructions':
      'Vous pouvez obtenir les coordonnées GPS à partir de Google Maps. Ouvrez Google Maps, trouvez l’emplacement. Faites un clic droit sur l’emplacement. En haut du menu qui s’ouvre sous la souris, vous verrez les coordonnées. Cliquez dessus pour copier, puis collez-les dans la case ici.',
  },
  email: {
    address: 'Adresse',
    email: 'E-mail',
    verificationStatus: 'Statut de vérification',
    createEmail: 'Créer un e-mail',
  },
  'addendum-editor': {
    title: 'Titre de l’addendum',
    'subtitle-optional': 'Sous-titre de l’addendum',
    'official-name': 'Nom officiel',
    'official-code': 'Code officiel',
    'select-country': 'Sélectionner le pays',
    'select-language': 'Sélectionner la langue',
    'select-location': 'Sélectionner la localisation',
    priority: 'Priorité (0-9)',
    'addendum-id': 'ID de l’addendum (créé automatiquement)',
    version: 'Version de l’addendum (automatique)',
    'created-on': 'Date de création (automatique)',
    'last-changed': 'Dernière modification (automatique)',
    id: 'ID de l’addendum (automatique)',
    'create-addendum': 'Créer un addendum',
    'submit-addendum': 'Mettre à jour l’addendum',
    'disable-addendum': 'Désactiver l’addendum',
    'enable-addendum': 'Activer l’addendum',
  },
  'form-editor': {
    title: 'Titre du formulaire',
    'subtitle-optional': 'Sous-titre du formulaire',
    'official-name': 'Nom officiel',
    'official-code': 'Code officiel',
    'select-country': 'Sélectionner le pays',
    'select-language': 'Sélectionner la langue',
    'select-location': 'Sélectionner la localisation',
    priority: 'Priorité (0-9)',
    'form-id': 'ID du formulaire (créé automatiquement)',
    version: 'Version du formulaire (automatique)',
    'created-on': 'Date de création (automatique)',
    'last-changed': 'Dernière modification (automatique)',
    id: 'ID du formulaire (automatique)',
    'create-form': 'Créer un formulaire',
    'submit-form': 'Mettre à jour le formulaire',
    'disable-form': 'Désactiver le formulaire',
    'enable-form': 'Activer le formulaire',
    associations: {
      'select-form': 'Sélectionner le formulaire'
    }
  },
  record: {
    filter: {
      'select-record-sealed': 'Dossier scellé ou non scellé',
      'any-is-sealed': 'Tout dossier',
      enabled: 'Dossiers scellés',
      disabled: 'Dossiers non scellés',
    },
    heading: {
      patient: 'Patient',
      form: 'Formulaire',
      'changed-created': 'Modifié / Créé',
      sealed: 'Scellé',
      'sign-here': 'Signez ici',
    },
    'search-by': {
      select: 'Sélectionner le type de recherche',
      'record-id': 'ID du dossier',
      'patient-name': 'Nom du patient',
    },
    'missing-patient-name': 'Aucun nom de patient enregistré',
    'incident-date': 'Date de l’incident',
    overview: {
      'section-title': 'Aperçu du dossier',
      'automatically-filled':
        'Ces informations seront automatiquement remplies à mesure que le dossier est complété.',
      name: 'Nom',
      titles: {
        patient: 'Détails du patient',
        administrative: 'Détails administratifs',
        timeline: 'Chronologie',
      },
      'created-date': 'Date de création',
      'created-by': 'Créé par',
      'last-changed-date': 'Date de la dernière modification',
      'last-changed-by': 'Modifié par',
      'record-version': 'Version du dossier',
      'form-id': 'ID du formulaire',
      'form-version': 'Version du formulaire',
      'form-title': 'Titre du formulaire',
      'form-name-and-code': 'Nom officiel et code du formulaire',
      'form-tile': 'Titre du formulaire',
      'case-id': 'ID du dossier à distance',
      'record-id': 'ID du dossier à distance',
      'sealed-date': 'Date de scellage',
      'sealed-by': 'Scellé par',
      'record-is-sealed': 'Le dossier est scellé',
      'record-overview': 'Aperçu du dossier',
      'sealing-records': 'Scellage des dossiers',
      'seal-complete-warning':
        'Ce dossier est complet et prêt à être scellé. Une fois scellé, vous ne pourrez plus modifier son contenu. Vous pouvez toutefois ajouter des dossiers associés. Êtes-vous sûr de vouloir sceller le dossier ?',
      'seal-incomplete-warning':
        'Ce dossier n’est pas complet ! Vérifiez quelles sections sont surlignées en rouge et remplissez-les ou ignorez-les si possible. Vous pouvez toujours sceller le dossier si vous en êtes sûr. Une fois scellé, vous ne pourrez plus modifier son contenu. Vous pouvez toutefois ajouter des dossiers associés. Souhaitez-vous sceller le dossier quand même ?',
      'select-associated-record-to-add':
        'Sélectionner le dossier associé à ajouter',
      'select-user-to-share-with':
        'Sélectionner l’utilisateur avec qui partager',
      'submit-first': 'Soumettre d’abord',
      'PDF-is-not-uploaded': 'PDF non téléchargé',
    },
    buttons: {
      'complete-record': 'Compléter le dossier',
      print: 'Imprimer',
      exit: 'Quitter',
      cancel: 'Annuler',
      delete: 'Supprimer',
      okay: 'D’accord',
      save: 'Enregistrer',
      other: 'Autre',
      logout: 'Déconnexion',
      'cancel-and-clear': 'Annuler et effacer',
      'save-and-exit': 'Enregistrer et quitter',
      'upgrade-form': 'Mettre à jour la version du formulaire',
      'fill-associated-form': 'Remplir le formulaire associé',
      'no-associated-form': 'Aucun formulaire associé',
      'tooltip-associated-form':
        'Les concepteurs de formulaires peuvent associer des formulaires à celui-ci, vous permettant d’ajouter des rapports de laboratoire, etc.',
      'share-record': 'Partager le dossier',
      'add-associated-form': 'Ajouter un formulaire associé',
    },
    placeholders: {
      search: 'Recherche',
      'describe-the-marker': 'Décrire le marqueur',
    },
    alerts: {
      error: 'Vous ne pouvez pas mettre à jour des enregistrements qui ont des modifications. Enregistrez d\'abord l\'enregistrement.',
      error2: 'Impossible de mettre à jour le formulaire',
      error3: 'L\'enregistrement est déjà à la dernière version du formulaire',
      error4: 'Vous ne pouvez pas rétrograder les versions de formulaire',
      error5: 'Échec de la mise à jour de la version du formulaire',
      confirmationTitle: 'Mise à jour du formulaire de l\'enregistrement',
      confirmationMessage: 'Les enregistrements conservent la version du formulaire lors de leur création. Parfois, les formulaires peuvent avoir des changements importants ou des corrections que vous souhaitez utiliser. Vous pouvez mettre à jour l\'enregistrement dans ce cas. Cela doit être fait rarement et seulement pour une raison claire. Si le formulaire est incorrectement modifié, cela peut entraîner une perte de données! Après la mise à jour, vous devez rouvrir l\'enregistrement.',
    }

  },
  form: {
    'not-filled': 'N/A',
    filter: {
      'select-form-enabled': 'Formulaires activés ou désactivés',
      'any-is-form-enabled': 'Tous les formulaires',
      enabled: 'Formulaires activés',
      disabled: 'Formulaires désactivés',
    },
    label: {
      'short-name': 'Nom court',
      coordinates: 'Coordonnées',
      'tag-optional': 'Étiquette (facultatif)',
      record: 'Dossier',
      form: 'Formulaire',
      dates: 'Dates',
    },
    'form-list': {
      title: 'Titre',
      'tags-or-form-ID': 'Étiquettes / ID du formulaire',
      'date-changed': 'Date modifiée',
      enabled: 'Activé',
    },

    Yes: 'Oui',
    No: 'Non',
    'user-editor': 'Éditeur d’utilisateur',
    'addendum-editor': 'Éditeur de l’addendum',
    'location-editor': 'Éditeur de l’emplacement',
    'fill-record': 'Remplir un dossier',
    'form-editor': 'Éditeur de formulaire',
    'select-form': 'Sélectionner un formulaire',
    'select-value': 'Sélectionner une valeur',
    'other-details': 'Décrire d’autres détails facultatifs',
    'details-about-other': 'Détails sur les autres (facultatif)',
    'add-photo': 'Ajouter une photo existante',
    'take-photo': 'Prendre une nouvelle photo',
    'save-photo': 'Enregistrer',
    'cancel-photo': 'Annuler',
    'delete-photo': 'Supprimer la photo',
    'mark-diagram': 'Annoter le diagramme',
    'repeated-one-time-below': 'répété une fois ci-dessous',
    'can-repeat-below': 'peut se répéter ci-dessous',
    repeated: 'répété',
    letter: 'Lettre',
    section: 'Section',
    'times-below': 'fois ci-dessous',
    'repeat-number': 'numéro',
    'repeat-out-of': 'sur',
    'enter-date-time': 'Entrer la date et l’heure',
    'enter-date': 'Entrer la date',
    'enter-number': 'Entrer le numéro',
    'enter-phone-number': 'Entrer le numéro de téléphone',
    'enter-email': 'Entrer l’email',
    'enter-text': 'Entrer le texte',
    'enter-long-text': 'Entrer un texte long',
    'enter-address': 'Entrer l’adresse',
    'select-other-option': 'Sélectionner une autre option',
    'select-the-option': 'Sélectionner l’option',
    'skip-question': 'Passer cette question',
    'camera-viewer': 'Vue de la caméra',
    'form-is-disabled': 'Formulaire désactivé',
    'form-is-enabled': 'Formulaire activé',
    'creating-form': 'Création du formulaire',
    'form-created': 'Formulaire créé',
    'not-yet-created': 'Pas encore créé',
    'form-checklist': 'LISTE DE CONTRÔLE DU FORMULAIRE',
    'no-form-body-filled-out': 'Aucun corps de formulaire rempli',
    'form-body-exists': 'Le corps du formulaire existe',
    'help-body-fill':
      'Cliquez sur l’onglet de l’éditeur et remplissez le corps du formulaire',
    'form-has-paths-periods': 'Le formulaire a des chemins avec des points',
    'from-paths-have-no-periods': 'Les chemins n’ont pas de points',
    'paths-with-periods': 'Chemins avec des points',
    'help-path-periods':
      'Votre formulaire a un chemin avec un point. Tout texte dans un formulaire qui précède un deux-points fait partie d’un chemin et ne peut pas contenir de point. Les chemins suivants contiennent des points.',
    'from-has-duplicate-paths': 'Il y a des chemins en double',
    'form-paths-are-unique': 'Les chemins du formulaire sont uniques',
    'dupicate-form-paths': 'Chemins de formulaire en double',
    'help-path-dublicate':
      'Votre formulaire a des chemins en double dans la définition. Vous devez renommer au moins l’un d’entre eux.',
    'no-PDF-uploaded': 'Aucun PDF téléchargé',
    'PDF-uploaded': 'PDF téléchargé',
    'help-no-PDF-uploaded':
      'Sans un formulaire PDF à remplir, nous générerons quand même un PDF avec un formatage automatique',
    'PDF-not-filled-out': 'Certaines parties du PDF ne sont pas remplies',
    'PDF-covered': 'Tous les champs PDF sont couverts',
    'help-PDF-not-filled-out': 'TODO implémenter ceci',
    'how-get-GPS-coordinates': 'Comment obtenir des coordonnées GPS',
    'location-is-disabled': 'L’emplacement est désactivé',
    'location-is-enabled': 'L’emplacement est activé',
    'created-on': 'Créé le (automatique)',
    'location-version': 'Version de l’emplacement (automatique)',
    'last-changed-on': 'Dernière modification le (automatique)',
    'selected-form': 'Formulaire sélectionné',
  },
  alert: {
    'insufficient-permissions': 'Autorisations insuffisantes',
    loading: 'Chargement',
    searching: 'Recherche',
    'PDF-only-available-on-web':
      'Le visualiseur PDF est uniquement disponible sur le web',
    'editing-is-web-only': 'Aperçu : La modification est réservée au web',
    'you-can-only-add-array-buffers':
      'BUG : Vous ne pouvez ajouter que des buffers de tableau',
    'loading-data': 'Chargement des données',
    'loading-form': 'Chargement du formulaire',
    'unsaved-data': 'Données non enregistrées',
    'select-gender':
      'Vous devez sélectionner un sexe ou un genre avant que cette image corporelle apparaisse',
    'getting-coordinates': 'Obtention des coordonnées',
  },
  menu: {
    account: 'Compte',
    profile: 'Profil',
    contacts: 'Contacts',
    group: 'Groupe',
    notification: 'Notification',
    orders: 'Commandes',
    settings: 'Paramètres',
    lock: 'Verrouiller',
    'privacy-policy': 'Politique de confidentialité',
    'help-and-support': 'Aide & Support',
    'refer-and-earn': 'Parrainez & Gagnez',
    home: 'Accueil',
  },
  home: {
    'add-user': 'Ajouter un utilisateur',
    'find-user': 'Trouver un utilisateur',
    'add-location': 'Ajouter un lieu',
    'find-location': 'Trouver un lieu',
    settings: 'Paramètres',
    training: 'Formation',
    "emails": "E-mails",
    "add-email": "Ajouter un e-mail",
    'edit-email': 'Modifier l\'email',
    'create-record': 'Créer un nouveau dossier',
    'find-record': 'Trouver un dossier',
    'your-records': 'Vos dossiers',
    sharing: 'Partage',
    'create-form': 'Créer un nouveau formulaire',
    'edit-form': 'Modifier un formulaire existant',
    'find-share': 'Trouver un partage',
    'create-addendum': 'Créer un nouvel addendum',
    'find-addendum': 'Trouver un addendum',
  },
  'user-editor': {
    'user-administrator': "L'utilisateur est un administrateur",
    'revoke-admin-permissions': "Révoquer les permissions d'administrateur",
    'make-user-an-admin': "Rendre l'utilisateur administrateur",
    'add-allowed-location': 'Ajouter un lieu autorisé',
    'add-permissions-for-location':
      'Ajouter des permissions pour au moins un lieu',
  },
  other: {
    help: 'Aide',
    'tell-me-more': "Dites m'en plus",
    more: 'Plus',
    'select-record': 'Sélectionner un dossier',
    welcome: 'Bienvenue',
  },
  accessible: {
    'leave-with-usaved-data':
      "Êtes-vous sûr de vouloir partir ? Les données non enregistrées seront perdues. Allez à la page d'aperçu et cliquez sur mise à jour.",
  },
  error: {
    'invalid-contents': 'Contenu invalide',
    'network-error': 'Erreur réseau',
    'server-error': 'Erreur serveur',
    'Required': 'Requis',
    'country': 'Pays',
    'locationID': 'ID de l\'emplacement',
    'language': 'Langue',
    'formUUID': 'Form UUID',
    'official-name': 'Nom officiel',
    'title': 'Titre',
    'priority': 'Priorité',
    'subtitle': 'Sous-titre',
    'String must contain at least 1 character(s)': 'La chaîne doit contenir au moins 1 caractère',
    'legalName': 'Nom officiel complet de l\'emplacement',
    'shortName': 'Nom court',
    'address': 'Adresse',
    'mailingAddress': 'Adresse postale',
    'phoneNumber': 'Numéro de téléphone',
    'phone_number': 'Numéro de téléphone',
    'email': 'Email',
    'Invalid email': 'Email invalide',
    'coordinates': 'Coordonnées',
    "Coordinates must be 2 numbers separated by a comma like 42340898141654343 -7108180417092166": 'Doit être 2 nombres séparés par une virgule comme 42.340898141654343, -71.08180417092166',
    'entityType': 'Type d\'entité',
    "Invalid enum value Expected 'medical-facility' | 'police-station' | 'refugee-camp'": 'Valeur enum invalide. Attendu \'établissement médical\' | \'poste de police\' | \'camp de réfugiés\'',
    'username': "Nom d'utilisateur",
    'birthdate': 'Date de naissance',
    'name': 'Nom court',
    'nickname': 'Surnom',
    'formal_name': 'Nom officiel complet',
    'gender': 'Sexe',
    'official_id_type': 'Type d\'identifiant officiel',
    'official_id_code': 'Code d\'identifiant officiel',
    'official_id_expires': 'Date d\'expiration de l\'identifiant',
    'expiry_date': 'Date d\'expiration de l\'utilisateur',
    'allowed_locations': 'Emplacements autorisés',
    'userType': 'Type d\'utilisateur',
    "Invalid enum value Expected 'Healthcare provider' | 'Associate' | 'Manager' | 'Form designer' | 'Researcher'": 'Valeur enum invalide. Attendu \'Prestataire de soins de santé\' | \'Associé\' | \'Manager\' | \'Concepteur de formulaires\' | \'Chercheur\'',
    'API manager does not exist': 'Le gestionnaire d\'API n\'existe pas',
  },
  country: {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: "Democratic People's Republic of Korea",
    KR: 'Republic of Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Lao',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine, State of',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin (French)',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten (Dutch part)',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States of America',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands (British)',
    VI: 'Virgin Islands (U.S.)',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
}
