import { getAddendum } from 'api/formdesigner'
import AddendumEditorOverview from 'components/AddendumEditorOverview'
import DashboardLayout from 'components/DashboardLayout'
import AddendumEditorAssociations from 'components/FormEditorAssociations'
import FormEditorComponent from 'components/FormEditor'
import AddendumEditorFiles from 'components/FormEditorFiles'
import AddendumEditorPrinted from 'components/FormEditorPrinted'
import AddendumEditorVersions from 'components/FormEditorVersions'
import { useLanguage } from 'components/LanguagePicker'
import Loading from 'components/Loading'
import { t } from 'i18n-js'
import { Box, CheckIcon, Select, VStack } from 'native-base'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RootStackScreenProps } from 'utils/formDesigner/navigation'
import {
  addOrReplaceFileToManifestByFilename,
  fetchManifestContents,
  lookupManifestByNameAndType,
  makeManifestEntry,
} from 'utils/manifests'
import useLeave from 'utils/useLeave'
import {FormManifestWithData, FormMetadata} from "../../utils/types/formMetadata";
import {FormType} from "../../utils/types/form";

function Tabs({
  tabName,
  setTabName,
}: {
  tabName: string
  setTabName: React.Dispatch<React.SetStateAction<string>>
}) {
  return (
    <Box
      width={{ md: '50%', base: '50%' }}
      _light={{ bg: { base: 'primary.900', md: 'white' } }}
    >
      <Box pl="10" w="2/4" maxW="300">
        <Select
          selectedValue={tabName}
          minWidth="100"
          accessibilityLabel="Select page"
          placeholder={t('tabsFormEditor.placeholder')}
          bg="white"
          _selectedItem={{
            bg: 'teal.600',
            endIcon: <CheckIcon size="5" />,
          }}
          mt={1}
          onValueChange={itemValue => setTabName(itemValue)}
        >
          <Select.Item label={t('tabsFormEditor.overview')} value="Overview" />
          <Select.Item label={t('tabsFormEditor.files')} value="Files" />
          <Select.Item label={t('tabsFormEditor.editor')} value="Editor" />
          <Select.Item label={t('tabsFormEditor.printPreview')} value="Printed" />
          <Select.Item label={t('tabsFormEditor.versions')} value="Versions" />
        </Select>
      </Box>
    </Box>
  )
}

const defaultAddendumMetadata: Partial<FormMetadata> = {
  'storage-version': '1.0.0',
  country: undefined,
  locationID: undefined,
  language: undefined,
  'official-name': undefined,
  'official-code': '',
  title: undefined,
  subtitle: '',
  priority: undefined,
  enabled: false,
  tags: 'sexual-assault',
  manifestHash: '',
  manifestMD5: '',
  userScopedLocalUUID: '',
  associatedForms: [],
  formUUID: undefined,
  formID: undefined,
  createdDate: undefined,
  createdByUUID: undefined,
  lastChangedDate: undefined,
  lastChangedByUUID: undefined,
  version: undefined,
}

const defaultAddendum: Partial<FormType> = {
  'storage-version': '1.0.0',
  skipConsent: undefined,
  common: {},
  sections: [],
}

const defaultManifest: FormManifestWithData = {
  'storage-version': '1.0.0',
  contents: [],
  root: '',
}

export default function AddendumEditor({
  route,
  navigation,
}: RootStackScreenProps<'AddendumEditor'>) {
  const [historyMode, setHistoryMode] = useState(false)
  const [changed, setChanged] = useState(false)
  const [waiting, setWaiting] = useState(null as null | string)
  const [addendumMetadata, setAddendumMetadataRaw] = useState(
    ((route.params && route.params.addendumMetadata) ||
      defaultAddendumMetadata) as Partial<FormMetadata>
  )
  const [manifest, setManifestRaw] = React.useState(defaultManifest)
  const latestVersion = useRef(addendumMetadata.version)
  const { language } = useLanguage()
  // This is how we keep track of whether the addendum has been changed.
  const setAddendumMetadata = useCallback(
    x => {
      setChanged(true)
      setAddendumMetadataRaw(x)
    },
    [setAddendumMetadataRaw]
  )
  const setManifest = useCallback(
    x => {
      setChanged(true)
      setManifestRaw(x)
    },
    [setManifestRaw]
  )

  // Load addendum on startup
  useEffect(() => {
    const fn = async () => {
      if (route.params && route.params.addendumMetadata) {
        //TODO add loading, now request dont work so it will load infinit

        // setWaiting(t('alert.loading-addendum'))
        const r = await getAddendum(route.params.addendumMetadata.formUUID)
        setAddendumMetadata(r.metadata)
        latestVersion.current = r.metadata.version
        const contents = await fetchManifestContents(r.manifest.contents)
        setManifest({
          'storage-version': '1.0.0',
          root: r.manifest.root,
          contents,
        })
        setWaiting(null)
        setChanged(false)
      }
    }
    fn()
  }, [])

  const [tabName, setTabName] = React.useState('Overview')
  const createMode = !(
    addendumMetadata.formUUID && addendumMetadata.formUUID !== ''
  )

  const setAddendum = useCallback(
    (addendum: FormType) => {
      const addendumData = JSON.stringify(addendum)
      const entry = makeManifestEntry(
        addendumData,
        'addendum.yaml',
        'text/yaml',
        false
      )
      const e = lookupManifestByNameAndType(
        manifest,
        'form.yaml',
        'text/yaml'
      )
      if (entry.sha256 !== (e && e.sha256)) {
        setChanged(true)
        setManifest({
          ...addOrReplaceFileToManifestByFilename(
            manifest,
            addendumData,
            'form.yaml',
            'text/yaml',
            false
          ),
          root: entry.sha256,
        })
      }
    },
    [manifest]
  )

  let page = null
  switch (tabName) {
    case 'Overview':
      page = (
        <AddendumEditorOverview
          addendumMetadata={addendumMetadata}
          setAddendumMetadata={setAddendumMetadata}
          manifest={manifest}
          changed={changed}
          setChanged={setChanged}
          setWaiting={setWaiting}
          latestVersion={latestVersion}
        />
      )
      break
    case 'Files':
      page = (
        <AddendumEditorFiles
          formMetadata={addendumMetadata}
          setFormMetadata={setAddendumMetadata}
          manifest={manifest}
          setManifest={setManifest}
        />
      )
      break
    case 'Associations':
      page = (
        <AddendumEditorAssociations
          formMetadata={addendumMetadata}
          setFormMetadata={setAddendumMetadata}
          manifest={manifest}
          setManifest={setManifest}
        />
      )
      break
    case 'Editor':
      page = (
        <FormEditorComponent
          formMetadata={addendumMetadata}
          manifest={manifest}
          setForm={setAddendum}
          addendum
        />
      )
      break
    case 'Printed':
      page = (
        <AddendumEditorPrinted
          formMetadata={addendumMetadata}
          manifest={manifest}
          setManifest={setManifest}
          setForm={setAddendum}
        />
      )
      break
    case 'Versions':
      page = (
        <AddendumEditorVersions
          formMetadata={addendumMetadata}
          manifest={manifest}
          changed={changed}
          historyMode={historyMode}
          setHistoryMode={setHistoryMode}
          setFormMetadata={setAddendumMetadata}
          setManifest={setManifest}
          latestVersion={latestVersion}
          navigation={navigation}
          setChanged={setChanged}
        />
      )
      break
  }

  useLeave(
    navigation,
    changed && !historyMode,
    t('alert.unsaved-data'),
    t('accessible.leave-with-usaved-data'),
    () => {}
  )

  return (
    <DashboardLayout
      title={t('addendum.addendum-editor')}
      displaySidebar={false}
      displayScreenTitle={false}
      backButton={true}
      navigation={navigation}
      middlebar={
        waiting || createMode ? (
          <></>
        ) : (
          <Tabs tabName={tabName} setTabName={setTabName} />
        )
      }
      mobileMiddlebar={
        waiting || createMode ? (
          <></>
        ) : (
          <Tabs tabName={tabName} setTabName={setTabName}/>
        )
      }
      fullWidth={tabName === 'Editor' || tabName === 'Printed'}
    >
      <>
        <VStack
          safeAreaBottom
          height="95%"
          borderRadius={{ md: '8' }}
          borderColor="coolGray.200"
          bg={tabName !== 'Editor' ? 'white' : null}
          px={
            tabName === 'Editor' || tabName === 'Printed'
              ? 0
              : {
                  base: 4,
                  md: 32,
                }
          }
        >
          {page}
        </VStack>
        <Loading loading={waiting} />
      </>
    </DashboardLayout>
  )
}
