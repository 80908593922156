import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {Editor as TinyMCEEditor} from "tinymce";

interface TextEditorProps {
  value: string;
  onChange: (html: string) => void;
  maxRows?: number;
}

interface MergeTag {
  value: string;
  title: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
     value,
     onChange,
     maxRows = 10,
}) => {
  const editorRef = useRef(null);

  const handleChange = (html: string) => {
    if (onChange) {
      onChange(html);
    }
  };

  const handleEditorChange = (html: string, editor: TinyMCEEditor) => {
    const content = editor.getContent({ format: 'text' });
    const lineCount = content.split('\n').length;

    if (lineCount <= maxRows) {
      handleChange(html);
    } else {
      editor.undoManager.undo();
    }
  };

  const init: Record<string, any> = {
    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | image | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
    tinycomments_mode: 'embedded',
    tinycomments_author: 'Author name',
    mergetags_list: [
      { value: 'First.Name', title: 'First Name' },
      { value: 'Email', title: 'Email' },
    ] as MergeTag[],
    file_picker_callback: (callback: (url: string, meta: { alt?: string; text?: string; source2?: string; poster?: string; }) => void, value: string, meta: { filetype: string }) => {
      if (meta.filetype === 'file') {
        callback('mypage.html', { text: 'My text' });
      }

      if (meta.filetype === 'image') {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function() {
          const file = (this as HTMLInputElement).files![0];
          const reader = new FileReader();

          reader.onload = function() {
            const base64 = reader.result as string;
            callback(base64, { alt: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    },
    forced_root_block: 'div',
    forced_root_block_attrs: {
      style: 'margin: 0;'
    },
    force_p_newlines: false,
    forced_newlines: false,
  };

  return (
        <Editor
            ref={editorRef}
            apiKey='catd90pfpcw4j3a2l2z2whvdg49c8ypi8bndvyhi2bvt6z24'
            init={init}
            value={value}
            onEditorChange={handleEditorChange}
        />
  );
};

export default TextEditor;
