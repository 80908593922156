import React, { useState, useEffect, useMemo } from 'react'
import DashboardLayout from 'components/DashboardLayout'
import { default as FormListComponent } from 'components/FormList'
import { FormMetadata } from 'utils/types/formMetadata'
import { useInfo, handleStandardErrors } from 'utils/errors'
import Loading from 'components/Loading'
import { RootStackScreenProps } from 'utils/provider/navigation'
import { getForms } from '../../utils/localStore/store'
import { t } from 'i18n-js'
import { useLanguage } from 'components/LanguagePicker'
import {findLocations} from "../../api/provider";
import {LocationType} from "../../utils/types/location";

export default function FindForm({
  route,
  navigation,
}: RootStackScreenProps<'FindForm'>) {
  const [forms, setForms] = useState([] as FormMetadata[])
  const [nextKey, setNextKey] = useState(undefined as undefined | string)

  const [locations, setLocations] = useState([] as LocationType[])
  const [nextKeyLocations, setNextKeyLocations] = useState(undefined as undefined | string)

  const [filterCountry, setFilterCountry] = useState('')
  const [filterLanguage, setFilterLanguage] = useState('')
  const [filterLocationID, setFilterLocationID] = useState('')
  const [filterText, setFilterText] = useState(undefined as undefined | string)
  const [filterSearchType, setFilterSearchType] = useState('')
  const [filterFormType, _] = useState("MAIN")

  const [error, warning, success] = useInfo()
  const [waiting, setWaiting] = useState(null as null | string)

  const doSearch = async () => {
    setWaiting(t('alert.loading'))
    try {
      const [forms, nextKey] = await getForms({
        country: filterCountry,
        language: filterLanguage,
        locationId: filterLocationID,
        text: filterText,
        searchType: filterSearchType,
        formType: filterFormType,
      })

      setForms(forms)
      setNextKey(nextKey)
    } catch (e) {
      handleStandardErrors(error, warning, success, e)
    }
    setWaiting(null)
  }
  const { language } = useLanguage()

  useEffect(() => {
    doSearch()
  }, [
    filterCountry,
    filterLanguage,
    filterLocationID,
    filterText,
    filterSearchType,
  ])

  const doSearchLocations = async () => {
    findLocations(
        () => setWaiting(t('alert.loading')),
        () => setWaiting(null),
        filterCountry,
        '',
        '',
        '',
        'enabled',
        e => handleStandardErrors(error, warning, success, e),
        setLocations,
        setNextKeyLocations
    )
  }

  useEffect(() => {
    doSearchLocations()
  }, [
    filterCountry,
  ])

  return (
    <DashboardLayout
      navigation={navigation}
      displaySidebar={false}
      displayScreenTitle={false}
      title={t('form.select-form')}
    >
      <>
        <FormListComponent
          locations={locations}
          forms={forms}
          hasMore={false}
          loadMore={() => null}
          filterCountry={filterCountry}
          setFilterCountry={setFilterCountry}
          filterLanguage={filterLanguage}
          setFilterLanguage={setFilterLanguage}
          filterLocationID={filterLocationID}
          setFilterLocationID={setFilterLocationID}
          filterSearchType={filterSearchType}
          setFilterSearchType={setFilterSearchType}
          filterText={filterText}
          setFilterText={setFilterText}
          doSearch={doSearch}
          selectItem={formMetadata => {
            navigation.pop()
            navigation.navigate('RecordEditor', {
              ...route.params,
              formMetadata,
              displayPageAfterOverview: true,
            })
          }}
        />
        <Loading loading={waiting} />
      </>
    </DashboardLayout>
  )
}
