import { useToast } from 'native-base'
import _ from 'lodash'
import { z } from 'zod'
import { t } from 'i18n-js'

export const useInfo = () => {
  const toast = useToast()
  return [
    (error: string, description?: string | undefined, otherData?: any) => {
      console.error(error, otherData)
      toast.show({
        title: error,
        status: 'error',
        placement: 'bottom',
        duration: 5000,
        isClosable: true,
        description,
        accessibilityAnnouncement: 'Encountered error ' + error,
      })
    },
    (warning: string, description?: string | undefined, otherData?: any) => {
      toast.show({
        title: warning,
        status: 'warning',
        placement: 'bottom',
        duration: 5000,
        isClosable: true,
        description,
      })
    },
    (success: string, description?: string | undefined, otherData?: any) => {
      const translatedTitle = t(`successfulCase.${success}`);
      const title = translatedTitle.includes('missing')  ? success : translatedTitle;
      toast.show({
        title: title,
        status: 'success',
        placement: 'bottom',
        duration: 5000,
        isClosable: true,
        description,
      })
    },
  ] as const
}

export function handleStandardErrors(
  error: any,
  warning: any,
  success: any,
  e: any
) {
  if (e instanceof z.ZodError) {
    // TODO i18n and clean up to be more human readable
    error(
        t('error.invalid-contents'),
        `${_.join(t(`error.${e.issues[0].path}`), '')} \n${t(`error.${e.issues[0].message.replaceAll(',','').replaceAll('.','')}`)}`,
        e
    )
    // e.issues[0].message === 'Required' ? t('error.required') : t('error.required'),
  } else if (_.isString(e)) {
    error(t(`error.${e}`));
  } else if (_.isError(e) && e.message === 'Network Error') {
    error(t('error.network-error'), undefined, e);
  } else {
    // TODO Server errors vs local errors
    error(t('error.server-error'), undefined, e);
    console.error('Error', e, typeof e);
  }
}
