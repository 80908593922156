import {
  Authenticator,
  Flex,
  Heading,
  Image,
  View,
  useTheme,
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import AsyncStorage from '@react-native-async-storage/async-storage'
import ButtonGroup from 'components/form-parts/ButtonGroup'
import * as Linking from 'expo-linking'
import { Box, Button } from 'native-base'
import React, { useEffect, useMemo, useState } from 'react'

import indexOf from 'lodash/indexOf'
import medicapt_logo from '../../assets/medicapt.png'
import phr_logo from '../../assets/phr.png'
import './Authentication.css'

import {
  UserKind,
  UserKindList,
  UserKindNames,
  reconfigureAmplifyForUserKind,
} from 'utils/userTypes'
import {LanguagePicker, useLanguage} from "../components/LanguagePicker";
import { t } from 'i18n-js'

function Header(userKind, setAccountType) {
  const { tokens } = useTheme()
  const { language, changeLanguage } = useLanguage()

  useEffect(() => {
    const supportedLanguages = ['en-US','en', 'fr'];
    const userLanguage = navigator.language.slice(0, 2);

    if (!supportedLanguages.includes(language)) {
      if (supportedLanguages.includes(userLanguage)) {
        changeLanguage(userLanguage);
      } else {
        AsyncStorage.getItem('language').then(value => {
          if (value === null){
            changeLanguage('en')
          }else {
            changeLanguage(value)
          }
        })
      }
    }
  }, [language]);



  return () => (
      <View style={{position: 'relative'}}>
        <Flex position={'absolute'} top={-20} right={-10} padding={10}>
          <LanguagePicker/>
        </Flex>
        <Flex
            position={'absolute'}
            top={-150}
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
        >
          <Image alt="Amplify logo" src={phr_logo} width={'50%'} />
          <Image alt="Amplify logo" src={medicapt_logo} width={'20%'} />
        </Flex>
        <View style={{marginTop: 140}}>
          <Flex
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
          >
            <Heading level={5} color="#d5001c" fontWeight="bold">
              {t('signIn.logInAs')}
            </Heading>
          </Flex>
          <ButtonGroup
              selected={userKind}
              options={{
                [UserKindNames[UserKind.Provider]]: UserKind.Provider,
                [UserKindNames[UserKind.Associate]]: UserKind.Associate,
              }}
              onPress={setAccountType}
              pt={2}
              pb={1}
              colorScheme="red"
              justifyContent="center"
          />
          <ButtonGroup
              selected={userKind}
              options={{
                [UserKindNames[UserKind.Manager]]: UserKind.Manager,
                [UserKindNames[UserKind.FormDesigner]]: UserKind.FormDesigner,
                [UserKindNames[UserKind.Researcher]]: UserKind.Researcher,
              }}
              onPress={setAccountType}
              pb={3}
              colorScheme="red"
              justifyContent="center"
          />
        </View>
      </View>
  )
}

function Footer() {
  return (
      <Box bg="#fff" p={4}>
        <Button onPress={() => Linking.openURL('mailto:help@medicapt.click')}>
          {t('signIn.askHelp')}
        </Button>
      </Box>
  )
}

export default function withAuthenticator(Component) {
  const AppWithAuthenticator = props => {
    const [userKind, setAccountType] = useState(null)
    useMemo(async () => {
      let r = await AsyncStorage.getItem('@last_account_selection')
      if (indexOf(UserKindList, r) < 0) {
        r = UserKind.Provider
      }
      setAccountType(r)
      reconfigureAmplifyForUserKind(r)
    }, [])
    useEffect(() => {
      async function fn() {
        if (userKind !== null) {
          reconfigureAmplifyForUserKind(userKind)
          await AsyncStorage.setItem('@last_account_selection', userKind)
        }
      }
      fn()
    })

    const defaultComponents = {
      Header: Header(userKind, setAccountType),
      SignIn: {
        Header: Authenticator.SignIn.Header,
        Footer: Authenticator.SignIn.Footer,
      },
      Footer: Footer,
    }

    if (userKind === null || userKind === undefined) {
      return <></>
    } else {
      return (
          <View style={{display: 'flex',flex: 1, width: '100%', height: '100%',backgroundColor: '#f5f5f5', justifyContent: 'center'}}>
            <Authenticator components={defaultComponents} variation="default">
              {({ signOut, user }) => (
                  <Component signOut={signOut} user={user} userKind={userKind} />
              )}
            </Authenticator>
          </View>
      )
    }
  }
  return AppWithAuthenticator
}