import React, { useState } from 'react';
import { HStack, IconButton, Input, Center, View, Modal as ModalNativeBase, Box, Text } from 'native-base';
import { Modal, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import { AntDesign } from '@expo/vector-icons';
import formatDate from 'utils/date.ts';
import { t } from 'i18n-js';
import { disabledBackground } from 'utils/formRendering/utils';
import FloatingLabelInput from 'components/FloatingLabelInput';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useLanguage } from './LanguagePicker';
import {enUS, fr} from "date-fns/locale";

export default function DateTimePicker({
   title,
   date,
   open,
   close,
   setDate,
   time,
   isDisabled,
   fancyLabel = undefined,
   error = '',
}) {
    const formatString = time ? 'yyyy-MM-dd h:mm a' : 'yyyy-MM-dd';
    const [modalVisible, setModalVisible] = useState(false);
    const [dateString, setDateString] = useState(
        _.isDate(date) ? formatDate(date, formatString) : ''
    );
    const { language } = useLanguage();

    const openInternal = () => {
        setModalVisible(true);
        open();
    };

    const onSave = date => {
        setDate(date);
        const dateString = formatDate(date, formatString);
        setDateString(dateString);
        setModalVisible(false);
        close();
    };

    const onClose = () => {
        setModalVisible(false);
        close();
    };

    const locales = {
        en: enUS,
        fr: fr,
    };

    return (
        <>
            <Center bg={isDisabled ? disabledBackground : undefined}>
                <HStack>
                    {fancyLabel ? (
                        <Box w="75%" maxW="200px" position={'relative'}>
                            <TouchableOpacity onPress={()=> setModalVisible(!modalVisible)}>
                                <Input
                                    isDisabled={isDisabled}
                                    size="md"
                                    placeholder={'YYYY-MM-DD'}
                                    value={dateString}
                                    isReadOnly
                                    borderColor={error ? 'red.500' : 'coolGray.200'}
                                    color={error ? 'red.500' : 'coolGray.800'}
                                    accessibilityLabel={t(
                                        time ? 'form.enter-date-time' : 'form.enter-date'
                                    )}
                                />
                            </TouchableOpacity>
                            {error && (
                                <Text color="red.500" mt={1} fontSize="xs" position={"absolute"} left={0} top={10}>
                                    {t(`error.${error}`)}
                                </Text>
                            )}
                        </Box>
                    ) : (
                            <Box w="75%" maxW="200px" position={'relative'}>
                                <TouchableOpacity onPress={()=> setModalVisible(!modalVisible)}>
                                    <Input
                                        isDisabled={isDisabled}
                                        mx="3"
                                        size="md"
                                        placeholder={t(time ? 'form.enter-date-time' : 'form.enter-date')}
                                        value={dateString}
                                        isReadOnly
                                        borderColor={error ? 'red.500' : 'coolGray.200'}
                                        color={error ? 'red.500' : 'coolGray.800'}
                                        accessibilityLabel={t(
                                            time ? 'form.enter-date-time' : 'form.enter-date'
                                        )}
                                    />
                                </TouchableOpacity>
                                {error && (
                                    <Text color="red.500" mt={1} fontSize="xs" position={"absolute"} left={0} top={5}>
                                        {t(`error.${error}`)}
                                    </Text>
                                )}
                            </Box>
                    )}
                    <Center>
                        <IconButton
                            isDisabled={isDisabled}
                            onPress={openInternal}
                            size="sm"
                            mt={0}
                            ml={2}
                            colorScheme="indigo"
                            variant="solid"
                            _icon={{
                                as: AntDesign,
                                name: 'calendar',
                            }}
                            accessibilityLabel={'Open calendar'}
                        />
                    </Center>
                </HStack>
            </Center>
            <Modal
                size="full"
                animationType="fade"
                transparent={true}
                visible={modalVisible}
                onClose={onClose}
            >
                <View
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.22)',
                        elevation: 20,
                        padding: 10,
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <ModalNativeBase.Content maxWidth="400px">
                        <ModalNativeBase.CloseButton onPressIn={onClose} />
                        <ModalNativeBase.Header>{title}</ModalNativeBase.Header>
                        <ModalNativeBase.Body width={400}>
                            <Center>
                                <DatePicker
                                    selected={date}
                                    onChange={onSave}
                                    inline
                                    locale={locales[language]}
                                    fixedHeight
                                    showYearDropdown={fancyLabel ? true : false}
                                />
                            </Center>
                        </ModalNativeBase.Body>
                    </ModalNativeBase.Content>
                </View>
            </Modal>
        </>
    );
}
