import React, { useState } from 'react';
import { Box, Text } from 'native-base';
import { t } from 'i18n-js';
import _ from 'lodash';
import { useLanguage } from './LanguagePicker';
import { LocationType } from '../utils/types/location';
import RNPickerSelect from 'react-native-picker-select';
import { useWindowDimensions } from 'react-native';

export default function SelectLocation({
                                           placeholder,
                                           locations,
                                           value,
                                           setValue,
                                           bg,
                                           any,
                                           size = 'md',
                                           itemProps,
                                           error,
                                           ...props
                                       }: {
    placeholder: string;
    locations: LocationType[];
    value: string | undefined;
    setValue: (locationID: string, locationUUID: string) => any;
    bg?: string;
    any?: string;
    size?: string;
    itemProps?: object;
    error?: string;
}) {
    const [selected, setSelected] = useState(value);
    const { language } = useLanguage();
    const { width } = useWindowDimensions();

    const inputBorderColor = error ? 'red' : 'coolGray.200';
    const inputTextColor = 'coolGray.800';

    const pickerItems = _.concat(
        any
            ? [{ label: t(any), value: '' }]
            : [],
        _.map(locations, e => ({
            label: _.isString(e) ? e : e.shortName,
            value: _.isString(e) ? e : e.locationID,
        }))
    );

    return (
        <Box position={'relative'}>
            <RNPickerSelect
                onValueChange={(id) => {
                    setSelected(id);
                    const l = _.find(
                        locations,
                        (l) => (_.isString(l) ? l : l.locationID) === id
                    )!;
                    if(l !== undefined) {
                        setValue(id, _.isUndefined(l) || _.isString(l) ? l : l.locationUUID);
                    }
                }}
                items={pickerItems}
                value={selected}
                placeholder={{ label: placeholder, value: null }}
                style={{
                    inputWeb:{
                        backgroundColor: 'transparent',
                        marginLeft: 12,
                        height: 40,
                        borderColor: error ? '#ee0e0e' : '#f1f1f1',
                        borderRadius: 5,
                        width: 200,
                    },
                    inputIOS: {
                        borderColor: inputBorderColor,
                        color: inputTextColor,
                        padding: 10,
                        width: width < 440 ? 150 : '100%',
                    },
                    inputAndroid: {
                        borderColor: inputBorderColor,
                        color: inputTextColor,
                        padding: 10,
                        width: width < 440 ? 150 : '100%',
                    },
                }}
                {...props}
            />
            {error && (
                <Text color="red.500" mt={1} fontSize="xs" position={'absolute'} left={3} top={10}>
                    {t(`error.${error}`)}
                </Text>
            )}
        </Box>
    );
}
