import { MaterialIcons } from '@expo/vector-icons'
import { createForm, findLocations, submitAddendum} from 'api/formdesigner'
import { standardHandler } from 'api/utils'
import AnyCountry from 'components/AnyCountry'
import FloatingLabelInput from 'components/FloatingLabelInput'
import Language from 'components/Language'
import NecessaryItem from 'components/NecessaryItem'
import SelectLocation from 'components/SelectLocation'
import { t } from 'i18n-js'
import _ from 'lodash'
import {
  Badge,
  Button,
  Center,
  CheckIcon,
  CloseIcon,
  HStack,
  Icon,
  Modal,
  Tooltip,
  VStack,
} from 'native-base'
import React, { useEffect, useState } from 'react'
import {handleStandardErrors, useInfo} from 'utils/errors'
import { isInManifest, lookupManifest } from 'utils/manifests'
import { useLanguage } from './LanguagePicker'
import FormSearch from './FormSearch'
import {FormMetadata,FormManifestWithData} from "../utils/types/formMetadata";
import {LocationType} from "../utils/types/location";

export function listDuplicates<T>(arr: T[]): T[] {
  return _.uniq(
    _.filter(arr, (val, i, iteratee) => _.includes(iteratee, val, i + 1))
  )
}

export function getAddendumPathsAndInfo(manifest: FormManifestWithData) {
  const f = lookupManifest(
    manifest,
    e => e.filetype === 'text/yaml' && e.filename === 'addendum.yaml'
  )
  if (f) {
    let pathsWithPeriods: { prefix: string; path: string }[] = []
    const allPaths: string[] = []
    function pathsPartMap(prefix: string, parts: any) {
      for (const key in parts) {
        if (_.includes(key, '.')) pathsWithPeriods.push({ prefix, path: key })
        paths(prefix + '.' + key, parts[key])
      }
    }
    function paths(prefix: string, data: any) {
      if (_.isArray(data)) {
        for (const e of data) {
          paths(prefix, e)
        }
      } else if (_.isObject(data)) {
        if ('type' in data) {
          allPaths.push(prefix)
          if (
            'show-parts-when-true' in data &&
            _.isArray(data['show-parts-when-true'])
          ) {
            paths(prefix + '.parts', data['show-parts-when-true'])
          }
          if ('list-with-parts' === data.type) {
            pathsPartMap(prefix, data.options)
          }
          if ('parts' in data && _.isArray(data.parts)) {
            pathsPartMap(prefix, data.parts)
          }
        } else {
          for (const key in data) {
            if (_.includes(key, '.'))
              pathsWithPeriods.push({ prefix, path: key })
            paths(prefix ? prefix + '.' + key : key, data[key])
          }
        }
      }
    }
    const data = JSON.parse(f.data)
    paths('', data)
    return { paths: allPaths, pathsWithPeriods }
  }
  return { paths: [], pathsWithPeriods: [] }
}

export default function AddendumEditorOverview({
  addendumMetadata,
  setAddendumMetadata,
  manifest,
  changed,
  setChanged,
  setWaiting,
  latestVersion,
}: {
  addendumMetadata: Partial<FormMetadata>
  setAddendumMetadata: React.Dispatch<
    React.SetStateAction<Partial<FormMetadata>>
  >
  manifest: FormManifestWithData
  changed: boolean
  setChanged: React.Dispatch<React.SetStateAction<Partial<boolean>>>
  setWaiting: React.Dispatch<React.SetStateAction<Partial<string | null>>>
  latestVersion: React.MutableRefObject<string | undefined>
}) {
  const [error, warning, success] = useInfo()
  const standardReporters = { setWaiting, error, warning, success }

  const createMode = !(
    addendumMetadata.formUUID && addendumMetadata.formUUID !== ''
  )
  const [selectedFormTitle, setSelectedFormTitle] = useState<
    string | undefined
  >(createMode ? undefined : addendumMetadata.parentFormID)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [duplicatePaths, setDuplicatePaths] = useState([] as string[])
  const [pathsWithPeriods, setPathsWithPeriods] = useState(
    [] as { prefix: string; path: string }[]
  )
  const [errors, setErrors] = useState<Partial<Record<keyof FormMetadata, string>>>({});
  const [locations, setLocations] = useState([] as LocationType[])
  const [nextKey, setNextKey] = useState(undefined as any)

  const { language } = useLanguage()

  useEffect(() => {
    const r = getAddendumPathsAndInfo(manifest)
    setDuplicatePaths(listDuplicates(r.paths))
    setPathsWithPeriods(r.pathsWithPeriods)
  }, [manifest])

  useEffect(() => {
    setAddendumMetadata({
      ...addendumMetadata,
      formType: 'ADDENDUM',
    })
  }, []);

  const doSearch = async () => {
    findLocations(
        () => setWaiting(t('alert.loading')),
        () => setWaiting(null),
        addendumMetadata.country,
        '',
        '',
        '',
        'enabled',
        e => handleStandardErrors(error, warning, success, e),
        setLocations,
        setNextKey
    )
  }

  useEffect(() => {
    doSearch()
  }, [addendumMetadata.country])

  const validateForm = (metadata: Partial<FormMetadata> = addendumMetadata) => {
    const newErrors: Partial<Record<keyof FormMetadata, string>> = {};

    if (!metadata.title) {
      newErrors.title = t('form-editor.required');
    }

    if (!metadata.subtitle) {
      newErrors.subtitle = t('form-editor.required');
    }

    if (!metadata['official-name']) {
      newErrors['official-name'] = t('form-editor.required');
    }

    if (!metadata['official-code']) {
      newErrors['official-code'] = t('form-editor.required');
    }


    if (!metadata.priority) {
      newErrors.priority = t('form-editor.required');
    }

    if (!metadata.country) {
      newErrors.country = t('form-editor.required');
    }

    if (!metadata.language) {
      newErrors.language = t('form-editor.required');
    }

    if (!metadata.locationID) {
      newErrors.locationID = t('form-editor.required');
    }

    setErrors(newErrors);

    return _.isEmpty(newErrors);
  };


  const handleCreateAddendum = () => {
    if (!validateForm()) return;

    standardHandler(
        standardReporters,
        t('addendum.creating-addendum'),
        t('addendum.addendum-created'),
        async () => {
          setAddendumMetadata(
              await createForm(
                  //@ts-ignore We validate this before the call
                  addendumMetadata
              )
          );
          setChanged(false);
          latestVersion.current = '1';
        }
    );
  };

  const submitAddendumWrapper = (
    updatedMetadata: Partial<FormMetadata>,
    updatedManifest: FormManifestWithData
  ) => {
    submitAddendum(
        updatedMetadata,
        updatedManifest,
        standardReporters,
        setAddendumMetadata,
        setChanged,
        () => {
          latestVersion.current = '' + (parseInt(latestVersion.current!) + 1)
        }
    )
  }

  const handleSubmitAddendum = () => {
    if (!validateForm()) return;

    submitAddendumWrapper(addendumMetadata, manifest);
  };

  const toggleAddendum = () => {
    const newAddendum = {
      ...addendumMetadata,
      enabled: !addendumMetadata.enabled,
    }
    setAddendumMetadata(newAddendum)
    submitAddendumWrapper(newAddendum, manifest)
  }

  const addForm = (associatedAddendumMetadata: FormMetadata) => {
    setSelectedFormTitle(associatedAddendumMetadata.formID)
    setIsModalOpen(false)
  }

  const handleInputChange = (key: keyof FormMetadata, value: string) => {
    setAddendumMetadata(prevState => {

      const updatedFormMetadata = { ...prevState, [key]: value };

      if (key === 'country') {
        updatedFormMetadata.locationID = '';
        updatedFormMetadata.locationUUID = '';
      }

      validateForm(updatedFormMetadata);
      return updatedFormMetadata;
    });
  };

  return (
    <>
      <VStack>
        <FloatingLabelInput
          label={t('addendum-editor.title')}
          value={addendumMetadata.title}
          setValue={v => handleInputChange('title', v)}
          error={errors.title}
        />
        <FloatingLabelInput
          label={t('addendum-editor.subtitle-optional')}
          value={addendumMetadata.subtitle}
          setValue={v => handleInputChange('subtitle', v)}
          error={errors.subtitle}
        />
        {!createMode && (
          <Center my={2}>
            <NecessaryItem
              isDone={!!addendumMetadata.enabled}
              todoText={t('addendum.addendum-is-disabled')}
              doneText={t('addendum.addendum-is-enabled')}
              size={4}
            />
          </Center>
        )}
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('addendum-editor.official-name')}
            w="100%"
            containerW="45%"
            value={addendumMetadata['official-name']}
            setValue={v => handleInputChange('official-name', v)}
            error={errors['official-name']}
          />
          <FloatingLabelInput
            label={t('addendum-editor.official-code')}
            w="100%"
            containerW="45%"
            value={addendumMetadata['official-code']}
            setValue={v => handleInputChange('official-code', v)}
            error={errors['official-code']}
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <AnyCountry
            placeholder={t('addendum-editor.select-country')}
            value={addendumMetadata.country}
            setValue={v => handleInputChange('country', v)}
            error={errors.country}
            mx={3}
            mt={1}

          />
          <Language
            placeholder={t('addendum-editor.select-language')}
            value={addendumMetadata.language}
            setValue={v => handleInputChange('language', v)}
            error={errors.language}
            mx={3}
            mt={1}
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <SelectLocation
            locations={locations}
            bg="white"
            placeholder={t('addendum-editor.select-location')}
            value={addendumMetadata.locationID}
            setValue={(id,uuid) => {
              handleInputChange('locationID', id)
              handleInputChange('locationUUID', uuid)
            }}
            error={errors.locationID}
            mx={3}
            mt={1}
          />
          <FloatingLabelInput
            label={t('addendum-editor.priority')}
            w="100%"
            containerW="45%"
            value={addendumMetadata.priority}
            error={errors.priority}
            setValue={v => handleInputChange('priority', v)}
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('addendum-editor.created-on')}
            w="100%"
            containerW="45%"
            isReadOnly
            placeholder={
              addendumMetadata.createdDate
                ? addendumMetadata.createdDate.toString()
                : t('addendum.not-yet-created')
            }
          />
          <FloatingLabelInput
            isReadOnly
            label={t('addendum-editor.last-changed')}
            placeholder={
              addendumMetadata.lastChangedDate
                ? addendumMetadata.lastChangedDate.toString()
                : t('addendum.not-yet-created')
            }
            w="100%"
            containerW="45%"
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('addendum-editor.version')}
            w="100%"
            containerW="45%"
            isReadOnly
            placeholder={
              addendumMetadata.version
                ? addendumMetadata.version
                : t('addendum.not-yet-created')
            }
          />
          <FloatingLabelInput
            label={t('addendum-editor.id')}
            w="100%"
            containerW="45%"
            isReadOnly
            placeholder={
              addendumMetadata.formID
                ? addendumMetadata.formID
                : t('addendum.not-yet-created')
            }
          />
        </HStack>
        <VStack mt={10} space={3} mb={5}>
          <Badge
            variant="solid"
            bg="coolGray.400"
            alignSelf="flex-start"
            _text={{
              color: 'coolGray.50',
              fontWeight: 'bold',
              fontSize: 'xs',
            }}
          >
            {t('addendum.addendum-checklist')}
          </Badge>
          <NecessaryItem
            isDone={isInManifest(manifest, e => e.filename == 'addendum.yaml')}
            todoText={t('addendum.no-addendum-body-filled-out')}
            doneText={t('addendum.addendum-body-exists')}
            size={4}
            optional={false}
            help={t('addendum.help-body-fill')}
          />
          <NecessaryItem
            isDone={_.isEmpty(pathsWithPeriods)}
            todoText={t('addendum.addendum-has-paths-periods')}
            doneText={t('addendum.from-paths-have-no-periods')}
            size={4}
            helpHeader={t('addendum.paths-with-periods')}
            help={
              t('addendum.help-path-periods') + JSON.stringify(pathsWithPeriods)
            }
          />
          <NecessaryItem
            isDone={_.isEmpty(duplicatePaths)}
            todoText={t('addendum.from-has-duplicate-paths')}
            doneText={t('addendum.addendum-paths-are-unique')}
            size={4}
            helpHeader={t('dupicate-addendum-paths')}
            help={`${t('addendum.help-path-dublicate')} ${duplicatePaths}`}
          />
          <NecessaryItem
            isDone={isInManifest(manifest, e => e.filename == 'addendum.pdf')}
            todoText={t('addendum.no-PDF-uploaded')}
            doneText={t('addendum.PDF-uploaded')}
            size={4}
            optional={true}
            help={t('addendum.help-no-PDF-uploaded')}
          />
          {isInManifest(manifest, e => e.filename == 'addendum.pdf') && (
            <NecessaryItem
              isDone={false}
              todoText={t('addendum.PDF-not-filled-out')}
              doneText={t('addendum.PDF-covered')}
              size={4}
              help={t('addendum.help-PDF-not-filled-out')}
            />
          )}
        </VStack>
        {createMode ? (
          <HStack my={5} justifyContent="center">
            <Button
              leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
              colorScheme="green"
              onPress={handleCreateAddendum}
            >
              {t('addendum-editor.create-addendum')}
            </Button>
          </HStack>
        ) : (
          <HStack my={5} justifyContent="space-between">
            <Button
              leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
              colorScheme="green"
              onPress={handleSubmitAddendum}
            >
              {t('addendum-editor.submit-addendum')}
            </Button>
            <Tooltip
              openDelay={0}
              label={t('record.overview.submit-first')}
              isDisabled={!changed}
            >
              <Button
                leftIcon={
                  addendumMetadata.enabled ? (
                    <CloseIcon size={'5'} mx={2} />
                  ) : (
                    <CheckIcon size={'5'} mx={2} />
                  )
                }
                colorScheme={addendumMetadata.enabled ? 'red' : 'green'}
                onPress={toggleAddendum}
              >
                {addendumMetadata.enabled
                  ? t('addendum-editor.disable-addendum')
                  : t('addendum-editor.enable-addendum')}
              </Button>
            </Tooltip>
          </HStack>
        )}
      </VStack>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="full"
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t('form.select-form')}</Modal.Header>
          <Modal.Body>
            {/* @ts-ignore addendum and form are identic */}
            <FormSearch selectItem={addForm} />
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => setIsModalOpen(false)}
              >
                {t('record.buttons.cancel')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  )
}
