import { MaterialIcons } from '@expo/vector-icons'
import {createForm, findLocations, submitForm} from 'api/formdesigner'
import { standardHandler } from 'api/utils'
import AnyCountry from 'components/AnyCountry'
import FloatingLabelInput from 'components/FloatingLabelInput'
import Language from 'components/Language'
import NecessaryItem from 'components/NecessaryItem'
import SelectLocation from 'components/SelectLocation'
import { t } from 'i18n-js'
import _ from 'lodash'
import {
  Badge,
  Button,
  Center,
  CheckIcon,
  CloseIcon,
  HStack,
  Icon,
  Tooltip,
  VStack,
} from 'native-base'
import React, { useEffect, useState } from 'react'
import {handleStandardErrors, useInfo} from 'utils/errors'
import { isInManifest, lookupManifest } from 'utils/manifests'
import { FormManifestWithData, FormMetadata } from 'utils/types/formMetadata'
import { useLanguage } from './LanguagePicker'
import {LocationType} from "../utils/types/location";

export function listDuplicates<T>(arr: T[]): T[] {
  return _.uniq(
    _.filter(arr, (val, i, iteratee) => _.includes(iteratee, val, i + 1))
  )
}

export function getFormPathsAndInfo(manifest: FormManifestWithData) {
  const f = lookupManifest(
    manifest,
    e => e.filetype === 'text/yaml' && e.filename === 'form.yaml'
  )
  if (f) {
    let pathsWithPeriods: { prefix: string; path: string }[] = []
    const allPaths: string[] = []
    function pathsPartMap(prefix: string, parts: any) {
      for (const key in parts) {
        if (_.includes(key, '.')) pathsWithPeriods.push({ prefix, path: key })
        paths(prefix + '.' + key, parts[key])
      }
    }
    function paths(prefix: string, data: any) {
      if (_.isArray(data)) {
        for (const e of data) {
          paths(prefix, e)
        }
      } else if (_.isObject(data)) {
        if ('type' in data) {
          allPaths.push(prefix)
          if (
            'show-parts-when-true' in data &&
            _.isArray(data['show-parts-when-true'])
          ) {
            paths(prefix + '.parts', data['show-parts-when-true'])
          }
          if ('list-with-parts' === data.type) {
            pathsPartMap(prefix, data.options)
          }
          if ('parts' in data && _.isArray(data.parts)) {
            pathsPartMap(prefix, data.parts)
          }
        } else {
          for (const key in data) {
            if (_.includes(key, '.'))
              pathsWithPeriods.push({ prefix, path: key })
            paths(prefix ? prefix + '.' + key : key, data[key])
          }
        }
      }
    }
    const data = JSON.parse(f.data)
    paths('', data)
    return { paths: allPaths, pathsWithPeriods }
  }
  return { paths: [], pathsWithPeriods: [] }
}

export default function FormEditorOverview({
  formMetadata,
  setFormMetadata,
  manifest,
  changed,
  setChanged,
  setWaiting,
  latestVersion,
}: {
  formMetadata: Partial<FormMetadata>
  setFormMetadata: React.Dispatch<React.SetStateAction<Partial<FormMetadata>>>
  manifest: FormManifestWithData
  changed: boolean
  setChanged: React.Dispatch<React.SetStateAction<Partial<boolean>>>
  setWaiting: React.Dispatch<React.SetStateAction<Partial<string | null>>>
  latestVersion: React.MutableRefObject<string | undefined>
}) {
  const [error, warning, success] = useInfo()
  const standardReporters = { setWaiting, error, warning, success }

  const createMode = !(formMetadata.formUUID && formMetadata.formUUID !== '')

  const [duplicatePaths, setDuplicatePaths] = useState([] as string[])
  const [pathsWithPeriods, setPathsWithPeriods] = useState(
    [] as { prefix: string; path: string }[]
  )

  const [errors, setErrors] = useState<Partial<Record<keyof FormMetadata, string>>>({});
  const [locations, setLocations] = useState([] as LocationType[])
  const [nextKey, setNextKey] = useState(undefined as any)


  const { language } = useLanguage()


   useEffect(() => {
    const r = getFormPathsAndInfo(manifest)
    setDuplicatePaths(listDuplicates(r.paths))
    setPathsWithPeriods(r.pathsWithPeriods)
  }, [manifest])

    const doSearch = async () => {
        findLocations(
            () => setWaiting(t('alert.loading')),
            () => setWaiting(null),
            formMetadata.country,
            '',
            '',
            '',
            'enabled',
            e => {
                handleStandardErrors(error, warning, success, e)
            },
            setLocations,
            setNextKey
        )
    }

    useEffect(() => {
        doSearch()
    }, [formMetadata.country])


    const handleCreateForm = () => {
        if (!validateForm()) return;

        standardHandler(
            standardReporters,
            t('form.creating-form'),
            t('form.form-created'),
            async () => {
                setFormMetadata(
                    await createForm(
                        //@ts-ignore We validate this before the call
                        formMetadata
                    )
                );
                setChanged(false);
                latestVersion.current = '1';
            }
        );
    };

    const validateForm = (metadata: Partial<FormMetadata> = formMetadata) => {
        const newErrors: Partial<Record<keyof FormMetadata, string>> = {};

        if (!metadata.title) {
            newErrors.title = t('form-editor.required');
        }

        if (!metadata.subtitle) {
            newErrors.subtitle = t('form-editor.required');
        }

        if (!metadata['official-name']) {
            newErrors['official-name'] = t('form-editor.required');
        }

        if (!metadata['official-code']) {
            newErrors['official-code'] = t('form-editor.required');
        }


        if (!metadata.priority) {
            newErrors.priority = t('form-editor.required');
        }

        if (!metadata.country) {
            newErrors.country = t('form-editor.required');
        }

        if (!metadata.language) {
            newErrors.language = t('form-editor.required');
        }

        if (!metadata.locationID) {
            newErrors.locationID = t('form-editor.required');
        }

        setErrors(newErrors);

        return _.isEmpty(newErrors);
    };


    const submitFormWrapper = (
        updatedMetadata: Partial<FormMetadata>,
        updatedManifest: FormManifestWithData
      ) =>
        submitForm(
          updatedMetadata,
          updatedManifest,
          standardReporters,
          setFormMetadata,
          setChanged,
          () => {
            latestVersion.current = '' + (parseInt(latestVersion.current!) + 1)
          }
        )

    const handleSubmitForm = () => {
        if (!validateForm()) return;

        submitFormWrapper(formMetadata, manifest);
    };


    const toggleForm = () => {
        if (!validateForm()) return;
        const newForm = { ...formMetadata, enabled: !formMetadata.enabled }
        setFormMetadata(newForm)
        submitFormWrapper(newForm, manifest)
  }

    const handleInputChange = (key: keyof FormMetadata, value: string) => {
        setFormMetadata(prevState => {

            const updatedFormMetadata = { ...prevState, [key]: value };

            if (key === 'country') {
                updatedFormMetadata.locationID = '';
                updatedFormMetadata.locationUUID = '';
            }

            validateForm(updatedFormMetadata);
            return updatedFormMetadata;
        });
    };

    return (
    <VStack>
      <FloatingLabelInput
        label={t('form-editor.title')}
        value={formMetadata.title}
        setValue={v => handleInputChange('title', v)}
        error={errors.title}
      />
      <FloatingLabelInput
        label={t('form-editor.subtitle-optional')}
        value={formMetadata.subtitle}
        setValue={v => handleInputChange('subtitle', v)}
        error={errors.subtitle}
      />
      {!createMode && (
        <Center my={2}>
          <NecessaryItem
            isDone={!!formMetadata.enabled}
            todoText={t('form.form-is-disabled')}
            doneText={t('form.form-is-enabled')}
            size={4}
          />
        </Center>
      )}
      <HStack alignItems="center" justifyContent="space-between">
        <FloatingLabelInput
          label={t('form-editor.official-name')}
          w="100%"
          containerW="45%"
          value={formMetadata['official-name']}
          setValue={v => handleInputChange('official-name', v)}
          error={errors["official-name"]}
        />
        <FloatingLabelInput
          label={t('form-editor.official-code')}
          w="100%"
          containerW="45%"
          value={formMetadata['official-code']}
          setValue={v => handleInputChange('official-code', v)}
          error={errors["official-code"]}
        />
      </HStack>
      <HStack alignItems="center" justifyContent="space-between">
        <AnyCountry
          placeholder={t('form-editor.select-country')}
          value={formMetadata.country}
          setValue={v => handleInputChange('country', v)}
          mx={3}
          mt={1}
          error={errors.country}
        />
        <Language
          placeholder={t('form-editor.select-language')}
          value={formMetadata.language}
          setValue={v => handleInputChange('language', v)}
          mx={3}
          mt={1}
          error={errors.language}
        />
      </HStack>
      <HStack alignItems="center" justifyContent="space-between">
        <SelectLocation
          locations={locations}
          bg="white"
          placeholder={t('form-editor.select-location')}
          value={formMetadata.locationID}
          setValue={(id,uuid) => {
              handleInputChange('locationID', id)
              handleInputChange('locationUUID', uuid)
          }}
          mx={3}
          mt={1}
          error={errors.locationID}
        />
        <FloatingLabelInput
          label={t('form-editor.priority')}
          w="100%"
          containerW="45%"
          value={formMetadata.priority}
          setValue={v => handleInputChange('priority', v)}
          error={errors.priority}
        />
      </HStack>
      <HStack alignItems="center" justifyContent="space-between">
        <FloatingLabelInput
          label={t('form-editor.created-on')}
          w="100%"
          containerW="45%"
          isReadOnly
          placeholder={
            formMetadata.createdDate
              ? formMetadata.createdDate.toString()
              : t('form.not-yet-created')
          }
        />
        <FloatingLabelInput
          isReadOnly
          label={t('form-editor.last-changed')}
          placeholder={
            formMetadata.lastChangedDate
              ? formMetadata.lastChangedDate.toString()
              : t('form.not-yet-created')
          }
          w="100%"
          containerW="45%"
        />
      </HStack>
      <HStack alignItems="center" justifyContent="space-between">
        <FloatingLabelInput
          label={t('form-editor.version')}
          w="100%"
          containerW="45%"
          isReadOnly
          placeholder={
            formMetadata.version ? formMetadata.version :  t('form.not-yet-created')
          }
        />
        <FloatingLabelInput
          label={t('form-editor.id')}
          w="100%"
          containerW="45%"
          isReadOnly
          placeholder={
            formMetadata.formID ? formMetadata.formID :  t('form.not-yet-created')
          }
        />
      </HStack>
      <VStack mt={10} space={3} mb={5}>
        <Badge
          variant="solid"
          bg="coolGray.400"
          alignSelf="flex-start"
          _text={{
            color: 'coolGray.50',
            fontWeight: 'bold',
            fontSize: 'xs',
          }}
        >
          {t('form.form-checklist')}
        </Badge>
        <NecessaryItem
          isDone={isInManifest(manifest, e => e.filename == 'form.yaml')}
          todoText={t('form.no-form-body-filled-out')}
          doneText={t('form.form-body-exists')}
          size={4}
          optional={false}
          help={t('form.help-body-fill')}
        />
        <NecessaryItem
          isDone={_.isEmpty(pathsWithPeriods)}
          todoText={t('form.form-has-paths-periods')}
          doneText={t('form.from-paths-have-no-periods')}
          size={4}
          helpHeader={t('form.paths-with-periods')}
          help={t('form.help-path-periods') + JSON.stringify(pathsWithPeriods)}
        />
        <NecessaryItem
          isDone={_.isEmpty(duplicatePaths)}
          todoText={t('form.from-has-duplicate-paths')}
          doneText={t('form.form-paths-are-unique')}
          size={4}
          helpHeader={t('dupicate-form-paths')}
          help={`${t('form.help-path-dublicate')} ${duplicatePaths}`}
        />
        <NecessaryItem
          isDone={isInManifest(manifest, e => e.filename == 'form.pdf')}
          todoText={t('form.no-PDF-uploaded')}
          doneText={t('form.PDF-uploaded')}
          size={4}
          optional={true}
          help={t('form.help-no-PDF-uploaded')}
        />
        {isInManifest(manifest, e => e.filename == 'form.pdf') && (
          <NecessaryItem
            isDone={false}
            todoText={t('form.PDF-not-filled-out')}
            doneText={t('form.PDF-covered')}
            size={4}
            help={t('form.help-PDF-not-filled-out')}
          />
        )}
      </VStack>
      {createMode ? (
        <HStack my={5} justifyContent="center">
          <Button
            leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
            colorScheme="green"
            onPress={handleCreateForm}
          >
            {t('form-editor.create-form')}
          </Button>
        </HStack>
      ) : (
        <HStack my={5} justifyContent="space-between">
          <Button
            leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
            colorScheme="green"
            onPress={handleSubmitForm}
          >
            {t('form-editor.submit-form')}
          </Button>
          <Tooltip
            openDelay={0}
            label={t('record.overview.submit-first')}
            isDisabled={!changed}
          >
            <Button
              leftIcon={
                formMetadata.enabled ? (
                  <CloseIcon size={'5'} mx={2} />
                ) : (
                  <CheckIcon size={'5'} mx={2} />
                )
              }
              colorScheme={formMetadata.enabled ? 'red' : 'green'}
              onPress={toggleForm}
            >
              {formMetadata.enabled
                ? t('form-editor.disable-form')
                : t('form-editor.enable-form')}
            </Button>
          </Tooltip>
        </HStack>
      )}
    </VStack>
  )
}
