import _ from 'lodash'
import { API } from 'aws-amplify'
import { LocationType, locationSchema } from 'utils/types/location'
import { UserType, userSchema } from 'utils/types/user'
import {
  formMetadataSchema,
  formManifestWithLinksSchema,
  FormMetadata,
  FormGetServer,
  FormRecMetadata,
} from 'utils/types/formMetadata'
import {
  recordMetadataSchemaByUser,
  recordMetadataSchema,
  recordManifestWithLinksSchema,
  RecordMetadataByUser,
  RecordMetadata,
  recordManifestWithMD5Schema,
  RecordManifestWithMD5,
  RecordPostServer,
  recordManifestWithPostLinksSchema,
  RecordGetServer,
} from 'utils/types/recordMetadata'
import { QueryFilterForType } from 'utils/types/url'
import { z } from 'zod'
import { schemaVersions, findUsersWrapper } from 'api/utils'
import {
  shareSchema,
  ShareByUser,
  Share,
} from 'utils/types/share'
import {FormType} from "../utils/types/form";

// User

export async function getUserByUsername(
  poolId: string,
  username: string
): Promise<Partial<UserType>> {
  const data = await API.get(
    'provider',
    '/provider/user/byId/' + poolId + '/' + username,
    {
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(userSchema)),
      },
    }
  )
  return userSchema.partial().parse(data)
}

export async function getUserByUUID(
  poolId: string,
  uuid: string
): Promise<Partial<UserType>> {
  const data = await API.get(
    'provider',
    '/provider/user/byUUID/' + poolId + '/' + uuid,
    {
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(userSchema)),
      },
    }
  )
  return userSchema.partial().parse(data)
}

export async function getUserByUUIDAnyPool(
  uuid: string
): Promise<Partial<UserType>> {
  const data = await API.get(
    'provider',
    '/provider/user/byUUIDAnyPool/' + uuid,
    {
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(userSchema)),
      },
    }
  )
  return userSchema.partial().parse(data)
}

export async function findUsers(
  pre: () => any,
  post: () => any,
  filterEnabledOrDisabled: string | undefined,
  filterLocation: string | undefined,
  filterSearchType: string | undefined,
  filterText: string | undefined,
  filterUserType: string | undefined,
  handleErrors: (err: any) => any,
  setUsers: (users: UserType[]) => any,
  setNextKey: (key: string) => any
) {
  return await findUsersWrapper('provider')(
    pre,
    post,
    filterEnabledOrDisabled,
    filterLocation,
    filterSearchType,
    filterText,
    filterUserType,
    handleErrors,
    setUsers,
    setNextKey
  )
}

// Location

export async function getLocation(locationUUID: string): Promise<LocationType> {
  const data = await API.get(
    'provider',
    '/provider/location/byId/' + locationUUID,
    {
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(locationSchema)),
      },
    }
  )
  return locationSchema.parse(data)
}

export async function findLocations(
    pre: () => any,
    post: () => any,
    filterCountry: string | undefined,
    filterLanguage: string | undefined,
    filterEntityType: string | undefined,
    filterText: string | undefined,
    filterEnabled: string | undefined,
    handleErrors: (err: any) => any,
    setLocations: (users: LocationType[]) => any,
    setNextKey: (key: string) => any
) {
  try {
    pre()
    let filters: QueryFilterForType<LocationType> = []
    if (filterCountry) filters.push({ country: { eq: filterCountry } })
    if (filterLanguage) filters.push({ language: { eq: filterLanguage } })
    if (filterEntityType) filters.push({ entityType: { eq: filterEntityType } })
    if (filterText) filters.push({ locationID: { contains: filterText } })
    if (filterEnabled) filters.push({ enabled: { eq: filterEnabled } })
    const data = await API.get('provider', '/provider/location', {
      queryStringParameters: {
        filter: JSON.stringify(filters),
      },
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(locationSchema)),
      },
    })

    // @ts-ignore If this fails, the server gave us bad data
    setLocations(data.items)
    setNextKey(data.nextKey)
  } catch (e) {
    handleErrors(e)
  } finally {
    post()
  }
}

// Forms

export async function getFormMetadata(formUUID: string): Promise<FormMetadata> {
  const data = await API.get(
    'provider',
    '/provider/form/metadataById/' + formUUID,
    {
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(formMetadataSchema)),
      },
    }
  )
  return formMetadataSchema.parse(data.metadata)
}

export async function getForm(formUUID: string): Promise<FormGetServer> {
  const data = await API.get('provider', '/provider/form/byId/' + formUUID, {
    headers: {
      AcceptedVersions: JSON.stringify({
        metadata: schemaVersions(formMetadataSchema),
        manifest: schemaVersions(formManifestWithLinksSchema),
      }),
    },
  })
  return {
    metadata: formMetadataSchema.parse(data.metadata),
    manifest: formManifestWithLinksSchema.parse(data.manifest),
  }
}

export async function getFormVersion(
  formUUID: string,
  version: string
): Promise<FormGetServer> {
  const data = await API.get(
    'provider',
    '/provider/form/byId/' + formUUID + '/' + version,
    {
      headers: {
        AcceptedVersions: JSON.stringify({
          metadata: schemaVersions(formMetadataSchema),
          manifest: schemaVersions(formManifestWithLinksSchema),
        }),
      },
    }
  )
  return {
    metadata: formMetadataSchema.parse(data.metadata),
    manifest: formManifestWithLinksSchema.parse(data.manifest),
  }
}

export type GetFormsFilters = {
  country?: string
  language?: string
  locationId?: string
  text?: string
  searchType?: string
  formType?: string
}
export async function getForms(
  filters: GetFormsFilters
): Promise<[FormMetadata[], string | undefined]> {
  let queryFilters: QueryFilterForType<FormRecMetadata> = []
  if (filters.country) queryFilters.push({ country: { eq: filters.country } })
  if (filters.language)
    queryFilters.push({ language: { eq: filters.language } })
  if (filters.locationId)
    queryFilters.push({ locationID: { eq: filters.locationId } })
  if (filters.formType) queryFilters.push({formType: {eq: filters.formType}})
  if (filters.searchType) queryFilters.push({searchType: {eq: filters.searchType}})
  if (filters.text) { // @ts-ignore
    queryFilters.push({ [filters.searchType]: { contains: filters.text } })
  }

  //
  // NB Providers are only allowed to see forms which are enabled
  queryFilters.push({ enabled: { eq: 'enabled' } })
  const data = await API.get('provider', '/provider/form', {
    queryStringParameters: {
      filter: JSON.stringify(queryFilters),
    },
    headers: {
      AcceptedVersions: JSON.stringify(schemaVersions(formMetadataSchema)),
    },
  })

  return [
    _.map(data.items, item => formMetadataSchema.parse(item)),
    z.string().optional().parse(data.nextKey),
  ]
}

// Records

export async function createRecord(
  record: RecordMetadataByUser
): Promise<RecordMetadata> {
  const data = await API.post('provider', '/provider/record', {
    body: recordMetadataSchemaByUser.strip().parse(record),
    headers: {
      AcceptedVersions: JSON.stringify(schemaVersions(recordMetadataSchema)),
    },
  })
  return recordMetadataSchema.parse(data)
}

export async function updateRecord(
  metadata: RecordMetadata,
  manifest: RecordManifestWithMD5
): Promise<RecordPostServer> {
  const data = await API.post(
    'provider',
    '/provider/record/byId/' + metadata.recordUUID,
    {
      body: {
        metadata: recordMetadataSchema.parse(metadata),
        manifest: recordManifestWithMD5Schema.parse(manifest),
      },
      headers: {
        AcceptedVersions: JSON.stringify({
          metadata: schemaVersions(recordMetadataSchema),
          manifest: schemaVersions(recordManifestWithPostLinksSchema),
        }),
      },
    }
  )
  return {
    metadata: recordMetadataSchema.parse(data.metadata),
    manifest: recordManifestWithPostLinksSchema.parse(data.manifest),
  }
}

export async function commitRecord(
  recordUUID: string,
  metadata: RecordMetadata
): Promise<RecordMetadata> {
  const data = await API.post(
    'provider',
    '/provider/record/commitById/' + recordUUID,
    {
      body: recordMetadataSchema.parse(metadata),
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(recordMetadataSchema)),
      },
    }
  )
  return recordMetadataSchema.parse(data.record)
}

export async function getRecordMetadata(
  recordUUID: string
): Promise<RecordMetadata> {
  const data = await API.get(
    'provider',
    '/provider/record/metadataById/' + recordUUID,
    {
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(recordMetadataSchema)),
      },
    }
  )
  return recordMetadataSchema.parse(data.metadata)
}

export async function getRecord(recordUUID: string): Promise<RecordGetServer> {
  const data = await API.get(
    'provider',
    '/provider/record/byId/' + recordUUID,
    {
      headers: {
        AcceptedVersions: JSON.stringify({
          metadata: schemaVersions(recordMetadataSchema),
          manifest: schemaVersions(recordManifestWithLinksSchema),
        }),
      },
    }
  )
  return {
    metadata: recordMetadataSchema.parse(data.metadata),
    manifest: recordManifestWithLinksSchema.parse(data.manifest),
  }
}

export type GetRecordsFilters = {
  locationId?: string
  createdByUUID?: string
  lastChangedByUUID?: string
  sealed?: string
  searchType?: string
  text?: string
}
export async function getRecords(
  filters: GetRecordsFilters
): Promise<[RecordMetadata[], string | undefined]> {
  let queryFilters: QueryFilterForType<RecordMetadata> = []
  if (filters.locationId)
    queryFilters.push({ locationID: { eq: filters.locationId } })
  if (filters.sealed) queryFilters.push({ sealed: { eq: filters.sealed } })
  if (filters.createdByUUID)
    queryFilters.push({ createdByUUID: { eq: filters.createdByUUID } })
  if (filters.lastChangedByUUID)
    queryFilters.push({ lastChangedByUUID: { eq: filters.lastChangedByUUID } })
  if (filters.text) { // @ts-ignore
    queryFilters.push({ [filters.searchType]: { contains: filters.text } })
  }

  const data = await API.get('provider', '/provider/record', {
    queryStringParameters: {
      filter: JSON.stringify(queryFilters),
    },
    headers: {
      AcceptedVersions: JSON.stringify(schemaVersions(recordMetadataSchema)),
    },
  })

  return [
    _.map(data.items, item => recordMetadataSchema.parse(item)),
    z.string().optional().parse(data.nextKey),
  ]
}

export async function sealRecord(
  metadata: RecordMetadata
): Promise<RecordMetadata> {
  const data = await API.post(
    'provider',
    '/provider/record/sealById/' + metadata.recordUUID,
    {
      body: recordMetadataSchema.parse(metadata),
      headers: {
        AcceptedVersions: schemaVersions(recordMetadataSchema),
      },
    }
  )
  return recordMetadataSchema.parse(data.record)
}

// Sharing

export async function getSharesForRecord(recordUUID: string): Promise<Share[]> {
  const data = await API.get(
    'provider',
    '/provider/share/record/byRecordId/' + recordUUID,
    {
      body: recordUUID,
      headers: {
        AcceptedVersions: '1.0.0',
      },
    }
  )
  return _.map(data.items, item => shareSchema.parse(item))
}

export async function createShareForRecord(share: ShareByUser): Promise<Share> {
  const data = await API.post(
    'provider',
    '/provider/share/record/byRecordId/' + share.recordUUID,
    {
      body: share,
      headers: {
        AcceptedVersions: '1.0.0',
      },
    }
  )
  return shareSchema.parse(data)
}

// Addendum

export async function createAddendum(addendum: FormByUserType) {
  formSchemaByUser.parse(addendum)
  const data = await API.post('provider', '/provider/addendum', {
    body: addendum,
    headers: {
      AcceptedVersions: JSON.stringify(schemaVersions(formSchema)),
    },
  })
  return formSchema.parse(data)
}

export async function getAddendum(addendumUUID: string) {
  const data = await API.get(
    'provider',
    '/provider/addendum/byId/' + addendumUUID,
    {
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(formSchema)),
      },
    }
  )
  return formSchema.parse(data)
}

export async function updateAddendum(addendum: FormType) {
  formSchema.parse(addendum)
  const data = await API.post(
    'provider',
    '/provider/addendum/byId/' + addendum.addendumUUID,
    {
      body: addendum,
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(formSchema)),
      },
    }
  )
  return formSchema.parse(data)
}

export async function deleteLocation(addendum: FormType) {
  formSchema.parse(addendum)
  const data = await API.del(
    'provider',
    '/provider/addendum/byId/' + addendum.addendumUUID,
    {
      body: addendum,
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(formSchema)),
      },
    }
  )
  return null
}

export async function findAddendums(
  pre: () => any,
  post: () => any,
  filterType: string | undefined,
  filterText: string | undefined,
  handleErrors: (err: any) => any,
  setAddendum: (users: FormType[]) => any,
  setNextKey: (key: string) => any
) {
  try {
    pre()
    let filters: QueryFilterForType<FormType> = []
    if (filterType) filters.push({ type: { eq: filterType } })
    if (filterText) filters.push({ name: { eq: filterText } })
    const data = await API.get('provider', '/provider/addendum', {
      queryStringParameters: {
        filter: JSON.stringify(filters),
      },
      headers: {
        AcceptedVersions: JSON.stringify(schemaVersions(formSchema)),
      },
    })

    setAddendum(data.items)
    setNextKey(data.nextKey)
  } catch (e) {
    handleErrors(e)
  } finally {
    post()
  }
}
