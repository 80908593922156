import DashboardLayout from 'components/DashboardLayout'
import FormSearch from 'components/FormSearch'
import { useLanguage } from 'components/LanguagePicker'
import { t } from 'i18n-js'
import React from 'react'

export default function FormList({ route, navigation }: any) {
  const { language } = useLanguage()
  return (
    <DashboardLayout
      navigation={navigation}
      displaySidebar={false}
      displayScreenTitle={false}
      title={t('form.select-form')}
    >
      <FormSearch
        selectItem={formMetadata => {
          navigation.navigate('FormEditor', {
            ...route.params,
            formMetadata,
          })
        }}
      />
    </DashboardLayout>
  )
}
