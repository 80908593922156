import React from 'react'
import {Select, ISelectProps, ISelectItemProps, Text, View} from 'native-base'
import { t } from 'i18n-js'
import _ from 'lodash'
import { useLanguage } from './LanguagePicker'
import {useWindowDimensions} from "react-native";

export const coutries = [
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'KH',
  'CM',
  'CA',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'CD',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'SZ',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MK',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
]

export default function AnyCountry({
  placeholder,
  value,
  setValue,
  bg,
  any,
  itemProps,
  error,
  ...props
}: {
  placeholder: string
  value: string | undefined
  setValue: (val: string) => any
  bg?: string
  any?: string
  itemProps?: ISelectItemProps
  error?: string;
} & ISelectProps) {
  const { language } = useLanguage()
  const { width } = useWindowDimensions();

  return (
    <View position={'relative'}>
      <Select
          bg={bg}
          // size="md"
          selectedValue={value}
          placeholder={placeholder}
          onValueChange={setValue}
          ml={3}
          borderColor={error ? 'red.500' : 'coolGray.200'}
          width={width < 440 ? 150 : '100%'}
          overflowX={'hidden'}
          {...props}
      >
        {_.concat(
            any
                ? [
                  <Select.Item
                      key={'__any__'}
                      label={t(any)}
                      value={''}
                      {...itemProps}
                  />,
                ]
                : [],
            _.map(coutries, e => (
                <Select.Item
                    // size="md"
                    key={e}
                    label={t('country.' + e)}
                    value={e}
                    {...itemProps}
                />
            ))
        )}
      </Select>
      {error && (
          <Text color="red.500" mt={1} fontSize="xs" position={'absolute'} left={3} top={10}>
            {t(`error.${error}`)}
          </Text>
      )}
    </View>
  )
}
