export default {
  languages: {
    en: 'English',
    fr: 'French',
  },
  gender: {
    male: 'Male',
    female: 'Female',
    intersex: 'Intersex',
    transgender: 'Transgender',
  },
  sex: {
    male: 'Male',
    female: 'Female',
    intersex: 'Intersex',
    transgender: 'Transgender',
  },
  tag: { 'sexual-assault': 'sexual assault' },
  heading: {
    name: 'Name',
    countryAndLanguage: 'Country & Language',
    lastChangedAndId: 'Last changed & ID',
    lastChangedAndFormId: 'Last changed & Form ID',
    type: 'Type',
    enabled: 'Enabled',
    usernameAndEmail: 'Enabled',
  },
  tabsFormEditor:{
    placeholder: 'Select page',
    overview: 'Overview',
    files:'Files',
    associations:'Associations',
    editor:'Editor',
    printPreview:'Print preview',
    versions:'Versions',
  },
  successfulCase:{
    'Form updated': 'Form updated',
    'Addendum updated': 'Addendum updated',
    'Location updated': 'Location updated',
    'Location created': 'Location created',
  },
  signIn:{
    logInAs: 'Log in as',
    username: 'Username',
    password: 'Password',
    signInText: 'Sign in',
    forgotPass: 'Forgot your password?',
    askHelp: 'Ask for help from help@medicapt.click',
  },
  user: {
    'Healthcare provider': 'Healthcare provider',
    Associate: 'Associate',
    Manager: 'Manager',
    'Form designer': 'Form designer',
    'FormDesigner': 'Form designer',
    'Provider': 'Healthcare provider',
    Researcher: 'Researcher',
    'select-user-type': 'Select user type',
    'any-user-type': 'Any user type',
    'enter-location': 'Narrow by location',
    'any-location': 'Any location',
    'search-by': {
      address: 'Address',
      username: 'Search username',
      email: 'Search email',
      'phone-number': 'Search phone #',
      name: 'Search name',
      'first-name': 'Search first name',
      'family-name': 'Search family name',
      'user-id-code': 'Search user ID code',
      select: 'Select search type',
      'official-name': 'official-name',
      'tags': 'tags',
    },
    'Male': 'Male',
    Female: 'Female',
    Transgender: 'Transgender',
    filter: {
      'select-user-enabled': 'Enabled or disabled users',
      'any-is-user-enabled': 'All users',
      enabled: 'Enabled users',
      disabled: 'Disabled users',
    },
    'search-enter': 'Enter search text',
    'full-official-name': 'Full official name',
    'short-name': 'Short name',
    nickname: 'Nickname',
    email: 'Email address',
    'phone-number': 'Phone number',
    'phone-number-with-help': 'Phone number like (+12223334444)',
    username: 'Username',
    address: 'Address',
    'user-type': 'User type',
    heading: {
      'official-id': 'Official ID',
      metadata: 'Metadata',
      bio: 'Bio',
    },
    'create-user': 'Create user',
    'create-email': 'Create email',
    'creating-email': 'Creating email',
    'submitting-email': 'Updating email',
    'submit-email': 'Update email',
    'disable-user': 'Disable user',
    'enable-user': 'Enable user',
    'submitting-user': 'Updating user',
    'submit-user': 'Update user',
    'submitted-user': 'Update user',
    'reset-password': 'Reset password',
    'password-reset': 'User password reset',
    'password-resetting': 'Resetting password',
    'email-confirmed': 'User email confirmed',
    'confirming-email': 'User email confirming',
    'confirm-email': 'Confirm email address',
    official_id_type: 'Official ID type',
    official_id_code: 'Official ID code',
    official_id_expires: 'ID expiration date',
    id: 'User ID code',
    'last-changed': 'Last changed',
    'created-on': 'Created on',
    'allowed-locations': 'Allowed locations',
    birthday: 'Birthday',
    'date-of-birth': 'Birthday',
    gender: 'Gender',
    'expiry-date': 'User expiration date',
    search: {
      'form-names-and-tags': 'Search form names and tags',
    },
    'resend-confirmation-email': 'Resend confirmation email',
    'resending-confirmation-email': 'Resending confirmation email',
    'resent-confirmation-email': 'Resent confirmation email',
    'Yes': 'Yes',
    'No': 'No',
    'userEnabled': 'User is enabled',
    'userDisabled': 'User is disabled',
  },
  common: {
    'read-only': 'read only',
    'automatically-created': 'automatically created',
    'space-separated-location-ids': 'space separated location IDs',
    'cannot-be-changed': 'cannot be changed',
  },
  addendum: {
    'addendum-name': 'Addendum name',
    type: 'Type',
    data: 'Data',
    'create-addendum': 'Create addendum',
    'submit-addendum': 'Submit addendum',
    'delete-addendum': 'Delete addendum',
    'select-addendum': 'Select addendum',
    'select-type': 'Select type',
    'any-type': 'Any type',
    search: 'Search',
    types: {
      type_1: 'fake type 1',
      type_2: 'fake type 2',
    },

    'not-filled': 'N/A',
    filter: {
      'select-addendum-enabled': 'Enabled or disabled forms',
      'any-is-addendum-enabled': 'All forms',
      enabled: 'Enabled forms',
      disabled: 'Disabled forms',
    },
    label: {
      'short-name': 'Short name',
      coordinates: 'Coordinates',
      'tag-optional': 'Tag (optional)',
      record: 'Record',
      addendum: 'Addendum',
      dates: 'Dates',
    },
    'addendum-list': {
      title: 'Title',
      'tags-or-addendum-ID': 'Tags / Addendum ID',
      'date-changed': 'Date changed',
      enabled: 'Enabled',
    },

    Yes: 'Yes',
    No: 'No',
    'user-editor': 'User Editor',
    'addendum-editor': 'Addendum Editor',
    'location-editor': 'Location Editor',
    'fill-record': 'Fill out a record',
    'select-value': 'Select a value',
    'other-details': 'Describe other optional details',
    'details-about-other': 'Details about other (optional)',
    'add-photo': 'Add an existing photo',
    'take-photo': 'Take a new photo',
    'save-photo': 'Save',
    'cancel-photo': 'Cancel',
    'delete-photo': 'Delete photo',
    'mark-diagram': 'Annotate the diagram',
    'repeated-one-time-below': 'repeated one time below',
    'can-repeat-below': 'can repeat below',
    repeated: 'repeated',
    letter: 'Letter',
    section: 'Section',
    'times-below': 'times below',
    'repeat-number': 'number',
    'repeat-out-of': 'out of',
    'enter-date-time': 'Enter date and time',
    'enter-date': 'Enter date',
    'enter-number': 'Enter number',
    'enter-phone-number': 'Enter phone number',
    'enter-email': 'Enter email',
    'enter-text': 'Enter text',
    'enter-long-text': 'Enter long text',
    'enter-address': 'Enter address',
    'select-other-option': 'Select other option',
    'select-the-option': 'Select the option',
    'skip-question': 'Skip answering this question',
    'camera-viewer': 'Camera view',
    'addendum-is-disabled': 'Addendum is disabled',
    'addendum-is-enabled': 'Addendum is enabled',
    'creating-addendum': 'Creating addendum',
    'addendum-created': 'Addendum created',
    'not-yet-created': 'Not yet created',
    'addendum-checklist': 'FORM CHECKLIST',
    'no-addendum-body-filled-out': 'No addendum body filled out',
    'addendum-body-exists': 'Addendum body exists',
    'help-body-fill': 'Click the editor tab and fill in the addendum body',
    'addendum-has-paths-periods': 'Addendum has paths with periods in it',
    'from-paths-have-no-periods': 'From paths have no periods',
    'paths-with-periods': 'Paths with periods',
    'help-path-periods':
      'Your addendum has a path with a period. Any text in a addendum that comes before a colon is part of a path and cannot have a period in it. The following paths have periods in them.',
    'from-has-duplicate-paths': 'From has duplicate paths',
    'addendum-paths-are-unique': 'Addendum paths are unique',
    'dupicate-addendum-paths': 'Dupicate addendum paths',
    'help-path-dublicate':
      'Your addendum has duplicate paths in the definition. You must rename at least one these.',
    'no-PDF-uploaded': 'No PDF uploaded',
    'PDF-uploaded': 'PDF uploaded',
    'help-no-PDF-uploaded':
      'Without a pdf addendum to fill out we will still generate a pdf with automatic formatting',
    'PDF-not-filled-out': 'Some parts of the PDF not filled out',
    'PDF-covered': 'All PDF fields covered',
    'help-PDF-not-filled-out': 'TODO implement this',
    'how-get-GPS-coordinates': 'How to get GPS coordinates',
    'location-is-disabled': 'Location is disabled',
    'location-is-enabled': 'Location is enabled',
    'created-on': 'Created on (automatic)',
    'location-version': 'Location version (automatic)',
    'last-changed-on': 'Last changed on (automatic)',
    'selected-addendum': 'Selected addendum',
  },
  versions:{
    title: 'Form history browser',
    latestVersion: 'Latest version',
    selectAVersion: 'Select a version of the form to load',
    alertCannot: 'You cannot make changes, but you can browse the form. Leave the from an come back to reset.',
    historyMode: 'History mode',
    showingVersion: 'Showing Version',
    createdOn: 'created on',
    makeLatest: 'Make this the latest version',
    alerts:{
      one: 'Reverting to a previous version will create a copy of that previous version. Now, the Form is at version ',
      two: '. We will revert to version ',
      three: ' by creating a new version, ',
      four: ' that is a copy of of ',
      five: '. Are you sure you want to do this?'
    },
    loading: 'Loading form',
    error1: 'Could not load form version ',
    error2: 'This form has not been saved on the server yet',
  },
  files: {
    downloadAllFiles: 'Download all files',
    tellMore: 'Tell me more',
    annotatingPdfs: 'Annotating pdfs',
    instruction: ' TODO Instructions for what to upload and how to annoate',
    describe: 'TODO Describe how to annotate a pdf in Acrobat and how to fill it out with Xs',
    images: 'IMAGES',
    uploadImage: 'Upload an image',
  },
  location: {
    'full-official-name': 'Full official location name',
    address: 'Address',
    'mailing-address': 'Mailing address',
    id: 'Location ID',
    'automatically-created': 'automatically created',
    'entity-type': 'Entity type',
    'create-location': 'Create location',
    'submit-location': 'Submit changes',
    'delete-location': 'Delete location',
    'enable-location': 'Enable location',
    'disable-location': 'Disable location',
    'select-country': 'Select country',
    'any-country': 'Any country',
    'select-language': 'Select language',
    'any-language': 'Any language',
    'select-entity-type': 'Select entity type',
    'any-entity-type': 'Any entity type',
    'select-default-language': 'Select default language',
    'any-default-language': 'Any default language',
    search: 'Search for location ID like ML3-XXX-YYY-ZZZ',
    edit:{
      title: 'Allowed locations',
    },
    entity: {
      'medical-facility': 'Medical facility',
      'police-station': 'Police station',
      'refugee-camp': 'Refugee camp',
    },
    'coordinates-instructions':
      'You can get GPS coordinates from Google Maps. Open Google Maps, find the location. Right click on the location. At the top of the menu that opens under the mouse, you will see the coordinates. Click them to copy, then paste them into the box here.',
  },
  email:{
    address: 'Address',
    email: 'Email',
    verificationStatus:' Verification Status',
    createEmail: 'Create email',
    filter: {
      enabled: 'Enabled emails',
      disabled: 'Disabled emails',
      'verified': 'Verified emails',
      'not verified': 'Not verified',
      'select-email-verfication': 'Select email verfication',
      'any-email-verfication': 'Any email verfication',
      'select-email-enabled': 'Enabled or disabled emails',
      'any-is-email-enabled': 'All emails',
    }
  },
  'addendum-editor': {
    title: 'Addendum title',
    'subtitle-optional': 'Addendum subtitle',
    'official-name': 'Official name',
    'official-code': 'Official code',
    'select-country': 'Select country',
    'select-language': 'Select language',
    'select-location': 'Select location',
    priority: 'Priority (0-9)',
    'addendum-id': 'Addendum ID (automatically created)',
    version: 'Addendum version (automatic)',
    'created-on': 'Creation date (automatic)',
    'last-changed': 'Last changed (automatic)',
    id: 'Addendum ID (automatic)',
    'create-addendum': 'Create addendum',
    'submit-addendum': 'Update addendum',
    'disable-addendum': 'Disable addendum',
    'enable-addendum': 'Enable addendum',
  },
  'form-editor': {
    title: 'Form title',
    'subtitle-optional': 'Form subtitle',
    'official-name': 'Official name',
    'official-code': 'Official code',
    'select-country': 'Select country',
    'select-language': 'Select language',
    'select-location': 'Select location',
    priority: 'Priority (0-9)',
    'form-id': 'Form ID (automatically created)',
    version: 'Form version (automatic)',
    'created-on': 'Creation date (automatic)',
    'last-changed': 'Last changed (automatic)',
    id: 'Form ID (automatic)',
    'create-form': 'Create form',
    'submit-form': 'Update form',
    'disable-form': 'Disable form',
    'enable-form': 'Enable form',
    associations: {
      'select-form': 'Select Form'
    },
    'required': 'Required',
  },
  record: {
    filter: {
      'select-record-sealed': 'Sealed or unsealed record',
      'any-is-sealed': 'Any record',
      enabled: 'Sealed records',
      disabled: 'Unsealed records',
    },
    heading: {
      patient: 'Patient',
      form: 'Form',
      'changed-created': 'Changed / Created',
      sealed: 'Sealed',
      'sign-here': 'Sign here',
    },
    'search-by': {
      select: 'Select search type',
      recordID: 'Record ID',
      patientName: 'Patient name',
    },
    'missing-patient-name': 'No patient name recorded',
    'incident-date': 'Incident date',
    overview: {
      'section-title': 'Record Overview',
      'automatically-filled':
        'This information will be automatically filled in as the record is completed.',
      name: 'Name',
      titles: {
        patient: 'Patient details',
        administrative: 'Adminstrative details',
        timeline: 'Timeline',
      },
      'created-date': 'Created date',
      'created-by': 'Created by',
      'last-changed-date': 'Last changed date',
      'last-changed-by': 'Last changed by',
      'record-version': 'Record version',
      'form-id': 'Form ID',
      'form-version': 'Form version',
      'form-title': 'Form title',
      'form-name-and-code': 'Form official name and code',
      'form-tile': 'Form title',
      'case-id': 'Remote case ID',
      'record-id': 'Remote case ID',
      'sealed-date': 'Sealed on date',
      'sealed-by': 'Sealed by',
      'record-is-sealed': 'Record is sealed',
      'record-overview': 'Record overview',
      'sealing-records': 'Sealing records',
      'seal-complete-warning':
        'This record is complete and ready to be sealed. Once you seal it, you cannot change its contents. But you can add associated records to it. Are you sure you want to seal the record?',
      'seal-incomplete-warning':
        'This record is not complete! Check which sections are highlighted in red and fill them out or skip their parts if possible. You can still seal the record if you are sure. Once you seal it, you cannot change its contents. But you can add associated records to it. Should we seal the record anyway?',
      'select-associated-record-to-add': 'Select associated record to add',
      'select-user-to-share-with': 'Select user to share with',
      'submit-first': 'Submit first',
      'PDF-is-not-uploaded': 'PDF is not uploaded',
    },
    buttons: {
      'complete-record': 'Complete record',
      print: 'Print',
      exit: 'Exit',
      cancel: 'Cancel',
      delete: ' Delete',
      okay: 'Okay',
      save: 'Save',
      other: 'Other',
      logout: 'Logout',
      'cancel-and-clear': 'Cancel and clear',
      'save-and-exit': 'Save and exit',
      'upgrade-form': 'Upgrade form version',
      'fill-associated-form': 'Fill associated form',
      'no-associated-form': 'No forms associated',
      'tooltip-associated-form':
        'Form designers can associate forms with this one, allowing you to append lab reports, etc.',
      'share-record': 'Share record',
      'add-associated-form': 'Add associated form',
    },
    placeholders: {
      search: 'Search',
      'describe-the-marker': 'Describe the marker',
    },
    alerts:{
      error: 'You cannot upgrade records that have changes. Save the record first.',
      error2: 'Could not upgrade the form',
      error3: 'Record is already at latest form version',
      error4: 'You cannot downgrade form versions',
      error5: 'Failed to upgrade the form version',
      confirmationTitle: 'Upgrading the record\'s form',
      confirmationMessage: 'Records keep the form version they were created at. Sometimes, forms can have important changes or fixes you want to use. You can upgrade the record in that case. This should be done rarely and only for a clear reason. If the form is incorrectly changed, this can lead to data loss! After upgrading you must reopen the record.',
    }
  },
  form: {
    'not-filled': 'N/A',
    filter: {
      'select-form-enabled': 'Enabled or disabled forms',
      'any-is-form-enabled': 'All forms',
      enabled: 'Enabled forms',
      disabled: 'Disabled forms',
    },
    label: {
      'short-name': 'Short name',
      coordinates: 'Coordinates',
      'tag-optional': 'Tag (optional)',
      record: 'Record',
      form: 'Form',
      dates: 'Dates',
    },
    'form-list': {
      title: 'Title',
      'tags-or-form-ID': 'Tags / Form ID',
      'date-changed': 'Date changed',
      enabled: 'Enabled',
    },

    Yes: 'Yes',
    No: 'No',
    'user-editor': 'User Editor',
    'addendum-editor': 'Addendum Editor',
    'location-editor': 'Location Editor',
    'fill-record': 'Fill out a record',
    'form-editor': 'Form Editor',
    'select-form': 'Select a form',
    'select-value': 'Select a value',
    'other-details': 'Describe other optional details',
    'details-about-other': 'Details about other (optional)',
    'add-photo': 'Add an existing photo',
    'take-photo': 'Take a new photo',
    'save-photo': 'Save',
    'cancel-photo': 'Cancel',
    'delete-photo': 'Delete photo',
    'mark-diagram': 'Annotate the diagram',
    'repeated-one-time-below': 'repeated one time below',
    'can-repeat-below': 'can repeat below',
    repeated: 'repeated',
    letter: 'Letter',
    section: 'Section',
    'times-below': 'times below',
    'repeat-number': 'number',
    'repeat-out-of': 'out of',
    'enter-date-time': 'Enter date and time',
    'enter-date': 'Enter date',
    'enter-number': 'Enter number',
    'enter-phone-number': 'Enter phone number',
    'enter-email': 'Enter email',
    'enter-text': 'Enter text',
    'enter-long-text': 'Enter long text',
    'enter-address': 'Enter address',
    'select-other-option': 'Select other option',
    'select-the-option': 'Select the option',
    'skip-question': 'Skip answering this question',
    'camera-viewer': 'Camera view',
    'form-is-disabled': 'Form is disabled',
    'form-is-enabled': 'Form is enabled',
    'creating-form': 'Creating form',
    'form-created': 'Form created',
    'not-yet-created': 'Not yet created',
    'form-checklist': 'FORM CHECKLIST',
    'no-form-body-filled-out': 'No form body filled out',
    'form-body-exists': 'Form body exists',
    'help-body-fill': 'Click the editor tab and fill in the form body',
    'form-has-paths-periods': 'Form has paths with periods in it',
    'from-paths-have-no-periods': 'From paths have no periods',
    'paths-with-periods': 'Paths with periods',
    'help-path-periods':
      'Your form has a path with a period. Any text in a form that comes before a colon is part of a path and cannot have a period in it. The following paths have periods in them.',
    'from-has-duplicate-paths': 'From has duplicate paths',
    'form-paths-are-unique': 'Form paths are unique',
    'dupicate-form-paths': 'Dupicate form paths',
    'help-path-dublicate':
      'Your form has duplicate paths in the definition. You must rename at least one these.',
    'no-PDF-uploaded': 'No PDF uploaded',
    'PDF-uploaded': 'PDF uploaded',
    'help-no-PDF-uploaded':
      'Without a pdf form to fill out we will still generate a pdf with automatic formatting',
    'PDF-not-filled-out': 'Some parts of the PDF not filled out',
    'PDF-covered': 'All PDF fields covered',
    'help-PDF-not-filled-out': 'TODO implement this',
    'how-get-GPS-coordinates': 'How to get GPS coordinates',
    'location-is-disabled': 'Location is disabled',
    'location-is-enabled': 'Location is enabled',
    'created-on': 'Created on (automatic)',
    'location-version': 'Location version (automatic)',
    'last-changed-on': 'Last changed on (automatic)',
    'selected-form': 'Selected form',
  },
  alert: {
    'insufficient-permissions': 'Insufficient Permissions',
    loading: 'Loading',
    searching: 'Searching',
    'PDF-only-available-on-web': 'The PDF viewer is only available on the web',
    'editing-is-web-only': 'Preview: Editing is web-only',
    'you-can-only-add-array-buffers': 'BUG: You can only add array buffers',
    'loading-data': 'Loading data',
    'loading-form': 'Loading form',
    'unsaved-data': 'Unsaved data',
    'select-gender':
      'You must select a gender or sex before this body image will appear',
    'getting-coordinates': 'Getting coordinates',
  },
  menu: {
    account: 'Account',
    profile: 'Profile',
    contacts: 'Contacts',
    group: 'Group',
    notification: 'Notification',
    orders: 'Orders',
    settings: 'Settings',
    lock: 'Lock',
    'privacy-policy': 'Privacy Policy',
    'help-and-support': 'Help & Support',
    'refer-and-earn': 'Refer & Earn',
    home: 'Home',
  },
  home: {
    'add-user': 'Add a user',
    'find-user': 'Find a user',
    'add-location': 'Add a location',
    'find-location': 'Find a location',
    settings: 'Settings',
    training: 'Training',
    emails: 'Emails',
    'add-email': 'Add email',
    'edit-email': 'Edit email',
    'create-record': 'Create a new record',
    'find-record': 'Find a record',
    'your-records': 'Your records',
    sharing: 'Sharing',
    'create-form': 'Create a new form',
    'edit-form': 'Edit an existing form',
    'find-share': 'Find a share',
    'create-addendum': 'Create a new addendum',
    'find-addendum': 'Find addendum',
  },
  'user-editor': {
    'user-administrator': 'User is an administrator',
    'revoke-admin-permissions': 'Revoke admin permissions',
    'make-user-an-admin': 'Make user an admin',
    'add-allowed-location': 'Add allowed location',
    'add-permissions-for-location': 'Add permissions for at least one location',
  },
  other: {
    help: 'Help',
    'tell-me-more': 'Tell me more',
    more: 'More',
    'select-record': 'Select a record',
    welcome: 'Welcome',
  },
  accessible: {
    'leave-with-usaved-data':
      'Are you sure you want to leave, unsaved data will be lost. Go to the overview page and click update.',
  },
  error: {
    'invalid-contents': 'Invalid contents',
    'network-error': 'Network Error',
    'server-error': 'Server error',
    'Required': 'Required',
    'country': 'Country',
    'locationID': 'Location ID',
    'language': 'Language',
    'formUUID': 'Form UUID',
    'official-name': 'Official Name',
    'title': 'Title',
    'priority': 'Priority',
    'subtitle': 'Subtitle',
    'String must contain at least 1 character(s)':'String must contain at least 1 character(s)',
    'legalName': 'Full official location name',
    'shortName': 'Short Name',
    'address': 'Address',
    'mailingAddress': 'Mailing address',
    'phoneNumber': 'Phone number',
    'phone_number': 'Phone number',
    'email': 'Email',
    'Invalid email': 'Invalid email',
    'coordinates': 'Coordinates',
    "Coordinates must be 2 numbers separated by a comma like 42340898141654343 -7108180417092166": 'must be 2 numbers separated by a comma like 42.340898141654343, -71.08180417092166',
    'entityType': 'Entity Type',
    "Invalid enum value Expected 'medical-facility' | 'police-station' | 'refugee-camp'": 'Invalid enum value. Expected \'medical-facility\' | \'police-station\' | \'refugee-camp\'',
    'username': "Username",
    'birthdate': 'Birthdate',
    'name': 'Short Name',
    'nickname': 'Nick name',
    'formal_name': 'Full official name',
    'gender': 'Gender',
    'official_id_type': 'Official ID type',
    'official_id_code': 'official ID code',
    'official_id_expires': 'ID Expiration date',
    'expiry_date': 'User expiration date',
    'allowed_locations': 'Allowed locations',
    'userType': 'userType',
    "Invalid enum value Expected 'Healthcare provider' | 'Associate' | 'Manager' | 'Form designer' | 'Researcher'": '"Invalid enum value Expected \'Healthcare provider\' | \'Associate\' | \'Manager\' | \'Form designer\' | \'Researcher',
    'API manager does not exist': 'API manager does not exist',
  },
  country: {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CD: 'Democratic Republic of the Congo',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: "Democratic People's Republic of Korea",
    KR: 'Republic of Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Lao',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine, State of',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin (French)',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten (Dutch part)',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States of America',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands (British)',
    VI: 'Virgin Islands (U.S.)',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
}
