import Form from 'components/Form'
import { Box, Center, HStack, Text, VStack } from 'native-base'
import React from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { FormType } from 'utils/types/form'
// @ts-ignore typescript doesn't support .web.js and .native.js files
import FormEditorComponent from 'components/FormEditorComponent'
import { FormManifestWithData, FormMetadata } from 'utils/types/formMetadata'
import { t } from 'i18n-js'
import { useLanguage } from './LanguagePicker'

const FormMemo = React.memo(Form)

function onCancel() {}

export default function FormEditor({
  formMetadata,
  manifest,
  setForm,
  addendum
}: {
  formMetadata: Partial<FormMetadata>
  manifest: FormManifestWithData
  setForm: (form: FormType) => any
  addendum?: boolean;
}) {
  const window = useWindowDimensions()
  const padding = Platform.OS === 'web' ? 0.03 : 0
  const ratio = Platform.OS === 'web' ? (window.width > 1000 ? 0.6 : 0.45) : 0
  const { language } = useLanguage()

  return (
    <VStack>
      {Platform.OS !== 'web' ? (
        <Center py={2}>
          <Text>{t('alert.editing-is-web-only')}</Text>
        </Center>
      ) : null}
      <HStack pt="0" space={3} justifyContent="center">
        <FormEditorComponent manifest={manifest} setForm={setForm} addendum={addendum} />
        <Box
          h={Math.round(window.height * 0.85) + 'px'}
          w={Math.round(window.width * (1 - ratio - padding)) + 'px'}
        >
          <FormMemo
            // @ts-ignore TODO partial forms should be ok
            formMetadata={formMetadata}
            formManifest={manifest}
            noRenderCache={true}
            onCancel={onCancel}
            disableMenu={true}
            overrideTransformation={'compact'}
          />
        </Box>
      </HStack>
    </VStack>
  )
}
