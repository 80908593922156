import {findAddendums, findLocations} from 'api/formdesigner'
import { default as AddendumListComponent } from 'components/FormList'
import Loading from 'components/Loading'
import { t } from 'i18n-js'
import React, { useEffect, useState } from 'react'
import { handleStandardErrors, useInfo } from 'utils/errors'
import { useLanguage } from './LanguagePicker'
import { FormMetadata } from "../utils/types/formMetadata"
import { useFocusEffect } from '@react-navigation/native'
import { useCallback } from 'react'
import {LocationType} from "../utils/types/location";

export default function AddendumSearch({
  selectItem,
}: {
  selectItem: (fm: FormMetadata) => any
}) {
  const [addendums, setAddendums] = useState([] as FormMetadata[])
  const [nextKey, setNextKey] = useState(undefined as any)
  const [nextKeyLocations, setNextKeyLocations] = useState(undefined as any)
  const [filterCountry, setFilterCountry] = useState('')
  const [filterLanguage, setFilterLanguage] = useState('')
  const [filterLocationID, setFilterLocationID] = useState('')
  const [filterEnabled, setFilterEnabled] = useState('')
  const [filterSearchType, setFilterSearchType] = useState('')
  const [filterText, setFilterText] = useState(undefined as undefined | string)
  const [error, warning, success] = useInfo()
  const [waiting, setWaiting] = useState(null as null | string)
  const { language } = useLanguage()
  const [filterFormType, _] = useState("ADDENDUM")
  const [locations, setLocations] = useState([] as LocationType[])

  const doSearch = async () => {
    findAddendums(
      () => setWaiting(t('alert.searching')),
      () => setWaiting(null),
      filterCountry,
      filterLanguage,
      filterLocationID,
      filterEnabled,
      filterSearchType,
      filterText,
      filterFormType,
      e => handleStandardErrors(error, warning, success, e),
      setAddendums,
      setNextKey
    )
  }

  useEffect(() => {
    doSearch()
  }, [
    filterCountry,
    filterLanguage,
    filterLocationID,
    filterEnabled,
    filterSearchType,
    filterText,
  ])

  const doSearchLocations = async () => {
    findLocations(
        () => setWaiting(t('alert.loading')),
        () => setWaiting(null),
        filterCountry,
        '',
        '',
        '',
        'enabled',
        e => handleStandardErrors(error, warning, success, e),
        setLocations,
        setNextKeyLocations
    )
  }

  useEffect(() => {
    doSearchLocations()
  }, [filterCountry])

  useFocusEffect(
      useCallback(() => {
        doSearch()
      }, [])
  )

  return (
    <>
      <AddendumListComponent
        forms={addendums}
        locations={locations}
        hasMore={false}
        loadMore={() => null}
        filterCountry={filterCountry}
        setFilterCountry={setFilterCountry}
        filterLanguage={filterLanguage}
        setFilterLanguage={setFilterLanguage}
        filterLocationID={filterLocationID}
        setFilterLocationID={setFilterLocationID}
        filterEnabled={filterEnabled}
        setFilterEnabled={setFilterEnabled}
        filterSearchType={filterSearchType}
        setFilterSearchType={setFilterSearchType}
        filterText={filterText}
        setFilterText={setFilterText}
        doSearch={doSearch}
        selectItem={selectItem}
      />
      <Loading loading={waiting} />
    </>
  )
}
