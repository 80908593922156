import { MaterialIcons } from '@expo/vector-icons'
import {createEmail, updateEmail} from 'api/manager'
import { standardHandler } from 'api/utils'
import FloatingLabelInput from 'components/FloatingLabelInput'
import Loading from 'components/Loading'
import { t } from 'i18n-js'
import _ from 'lodash'
import {
  Button,
  HStack,
  Icon,
  VStack,
} from 'native-base'
import React, { useState } from 'react'
import { useInfo } from 'utils/errors'
import {EmailType} from "../utils/types/email";

export default function EmailEditor({
  email,
  setEmail,
  reloadPrevious,
}: {
  email: Partial<EmailType>
  setEmail: React.Dispatch<React.SetStateAction<Partial<EmailType>>>
  reloadPrevious: React.MutableRefObject<boolean>
}) {
  const [error, warning, success] = useInfo()
  const [waiting, setWaiting] = useState(null as null | string)
  const [errors, setErrors] = useState<Partial<Record<keyof EmailType, string>>>({});

  const standardReporters = { setWaiting, error, warning, success }

  const createMode = !(email.emailUUID && email.emailUUID !== '')

  const validateForm = (metadata: Partial<EmailType> = email) => {
    const newErrors: Partial<Record<keyof EmailType, string>> = {};

    if (!metadata.email) {
      newErrors.email = t('form-editor.required');
    }

    if (!metadata.address) {
      newErrors.address= t('form-editor.required');
    }

    if (!metadata.name) {
      newErrors.name= t('form-editor.required');
    }

    setErrors(newErrors);

    return _.isEmpty(newErrors);
  };

  const handleCreateEmail = () => {
    if (!validateForm()) return;
    standardHandler(
        standardReporters,
        t('user.creating-email'),
        t('user.create-email'),
        async () => {
          await createEmail(
              //@ts-ignore We validate this before the call
              email
          )
          reloadPrevious.current = true
        }
    )
  }

  const submitEmail = (
      updatedEmail: Partial<EmailType>,
      inProgressMessage?: string,
      message?: string
  ) => {
    if (!validateForm()) return;
    standardHandler(
        standardReporters,
        inProgressMessage || t('user.submitting-email'),
        message || t('user.submit-email'),
        async () => {
          const r = await updateEmail(
              //@ts-ignore We validate this before the call
              updatedEmail
          )
          reloadPrevious.current = true
        }
    )
  }

  const handleSubmitEmail = () => submitEmail(email)

  const handleInputChange = (key: keyof EmailType, value: string) => {
    setEmail(prevState => {
      const updatedFormMetadata = { ...prevState, [key]: value };
      validateForm(updatedFormMetadata);
      return updatedFormMetadata;
    });
  };

  return (
    <>
      <VStack>
        <FloatingLabelInput
            label={t('heading.name')}
            value={email.name}
            setValue={v => handleInputChange('name', v)}
            error={errors.name}
        />
        <FloatingLabelInput
            label={t('email.email')}
            value={email.email}
            setValue={v => handleInputChange('email', v)}
            error={errors.email}
        />
        <FloatingLabelInput
            label={t('location.address')}
            value={email.address}
            setValue={v => handleInputChange('address', v)}
            error={errors.address}
        />
        {createMode ?
            <HStack mt={5} justifyContent="center">
              <Button
                  leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
                  colorScheme="green"
                  onPress={handleCreateEmail}
              >
                {t('email.createEmail')}
              </Button>
            </HStack>
        :
            <HStack mt={5} justifyContent="center">
              <Button
                  leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
                  colorScheme="green"
                  onPress={handleSubmitEmail}
              >
                {t('location.submit-location')}
              </Button>
            </HStack>
        }
      </VStack>
      <Loading loading={waiting} />
    </>
  )
}
