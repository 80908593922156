import {z} from "zod";

export const emailSchemaByUser = z.object({
	name: z.string().min(1, "Name is required"),
	email: z.string().email("Invalid email format"),
	address: z.string().min(1, "Address is required"),
});

export const emailSchema = emailSchemaByUser.extend({
	emailUUID: z.string().uuid(),
	enabled: z.boolean().default(true),
	verificationStatus: z.boolean().default(false),
})

export const emailSchemaStrip = emailSchema.strip()

export type EmailTypeByUser = z.infer<typeof emailSchemaByUser>
export type EmailType = z.infer<typeof emailSchema>
