import DashboardLayout from 'components/DashboardLayout'
import { t } from 'i18n-js'
import { VStack } from 'native-base'
import React, { useRef, useState } from 'react'
import { RootStackScreenProps } from 'utils/manager/navigation'
import { LocationType } from 'utils/types/location'
import EmailEditor from "../../components/EmailEditor";
import {EmailType} from "../../utils/types/email";

export const defaultLocation: Partial<LocationType> = {
  'storage-version': '1.0.0',
  locationUUID: '',
  locationID: '',
  country: undefined,
  language: undefined,
  legalName: undefined,
  shortName: undefined,
  entityType: undefined,
  mailingAddress: undefined,
  coordinates: undefined,
  phoneNumber: undefined,
  email: undefined,
  createdDate: undefined,
  createdByUUID: undefined,
  lastChangedByUUID: undefined,
  lastChangedDate: undefined,
  enabled: false,
  tags: new Set(),
  version: undefined,
}

export default function EditEmail({
  route,
  navigation,
}: RootStackScreenProps<'EditEmail'>) {
  const [email, setEmail] = useState(
    ((route.params && route.params.email) ||
      defaultLocation) as Partial<EmailType>
  )

  const reloadPrevious = useRef(false)

  return (
    <DashboardLayout
      title={email.emailUUID === undefined ? t('home.add-email') : t('home.edit-email')}
      displaySidebar={false}
      displayScreenTitle={false}
      backButton={true}
      navigation={navigation}
      middlebar={<></>}
      mobileMiddlebar={<></>}
      fullWidth={false}
      route={route}
      reloadPrevious={reloadPrevious}
    >
      <VStack
        safeAreaBottom
        height="95%"
        borderRadius={{ md: '8' }}
        borderColor="coolGray.200"
        bg={'white'}
        px={{
          base: 4,
          md: 32,
        }}
      >
        <EmailEditor
          email={email}
          setEmail={setEmail}
          reloadPrevious={reloadPrevious}
        />
      </VStack>
    </DashboardLayout>
  )
}
