import AddendumSearch from 'components/AddendumSearch'
import DashboardLayout from 'components/DashboardLayout'
import { useLanguage } from 'components/LanguagePicker'
import { t } from 'i18n-js'
import React from 'react'

export default function AddendumList({ route, navigation }: any) {
  const { language } = useLanguage()
  return (
    <DashboardLayout
      navigation={navigation}
      displaySidebar={false}
      displayScreenTitle={false}
      title={t('addendum.select-addendum')}
    >
      <AddendumSearch
        selectItem={addendumMetadata => {
          navigation.navigate('AddendumEditor', {
            ...route.params,
            addendumMetadata,
          })
        }}
      />
    </DashboardLayout>
  )
}
