import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'
import NecessaryItem from 'components/NecessaryItem'
import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'
import _ from 'lodash'
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  FlatList,
  HStack,
  Icon,
  IconButton,
  Image,
  Popover,
  Text,
  VStack,
} from 'native-base'
import React from 'react'
import { readImage, stripFileExtension } from 'utils/forms'
// @ts-ignore TODO TS doesn't understand .native.js and .web.js files
import DebouncedTextInput from 'components/form-parts/DebouncedTextInput'
import { tryConvertToWebP } from 'utils/imageConverter'
import {
  addFileToManifest,
  changeFilenameInManifest,
  filterManifest,
  generateZip,
  isImage,
  isInManifest,
} from 'utils/manifests'
import { FormManifestWithData, FormMetadata } from 'utils/types/formMetadata'
import { t } from 'i18n-js'
import { useLanguage } from './LanguagePicker'

export default function FormEditorFiles({
  formMetadata,
  setFormMetadata,
  manifest,
  setManifest,
}: {
  formMetadata: Partial<FormMetadata>
  setFormMetadata: React.Dispatch<React.SetStateAction<Partial<FormMetadata>>>
  manifest: FormManifestWithData
  setManifest: React.Dispatch<React.SetStateAction<FormManifestWithData>>
}) {
  const { language } = useLanguage()
  const pickPdf = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
      multiple: true,
      copyToCacheDirectory: true,
    })
    if (result.type !== 'cancel') {
      const data = await readImage(result.uri, 'data:application/pdf,')
      if (data) {
        if (_.isArrayBuffer(data))
          throw new Error(t('alert.you-can-only-add-array-buffers'))
        setManifest(
          addFileToManifest(
            filterManifest(manifest, f => f.filename !== 'form.pdf'),
            data,
            'form.pdf',
            'application/pdf',
            true
          )
        )
      }
    }
  }

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      allowsMultipleSelection: true,
      base64: true,
      quality: 0.8,
    })
    if (!result.cancelled) {
      // TODO It doesn't seem like we can get file names :(
      let nr = _.size(manifest.contents)
      for (const uploaded of result.selected) {
        setManifest(
          addFileToManifest(
            manifest,
            await tryConvertToWebP(uploaded.uri),
            'image' + nr + '.webp',
            'image/webp',
            true
          )
        )
        nr++
      }
    }
  }

  const removePdf = () => {
    setManifest(filterManifest(manifest, f => f.filename !== 'form.pdf'))
  }

  const pdfHasAnnotations = false

  return (
    <VStack my="2" space={3}>
      <Center py={3}>
        <Button
          fontWeight="bold"
          color="coolGray.800"
          bg="info.500"
          fontSize="md"
          onPress={() => generateZip(formMetadata, manifest)}
          leftIcon={<Icon as={Feather} name="download-cloud" size="sm" />}
        >
          {t('files.downloadAllFiles')}
        </Button>
      </Center>
      <HStack alignItems="center" justifyContent="space-between">
        {isInManifest(manifest, e => e.filename == 'form.pdf') ? (
          <VStack
            space="2"
            px={{ base: '4', md: '8' }}
            justifyContent="flex-end"
          >
            <HStack w="100%" space={3} alignItems="center">
              <NecessaryItem
                isDone={pdfHasAnnotations}
                todoText="PDF has no annotations!"
                doneText="PDF has annotations"
                size={4}
                optional={false}
              />
              <Popover
                trigger={triggerProps => {
                  return (
                    <Button
                      {...triggerProps}
                      variant="link"
                      colorScheme="secondary"
                    >
                      {t('files.tellMore')}
                    </Button>
                  )
                }}
              >
                <Popover.Content accessibilityLabel="Delete Customerd" w="64">
                  <Popover.Arrow />
                  <Popover.CloseButton />
                  <Popover.Header>{t('files.annotatingPdfs')}</Popover.Header>
                  <Popover.Body>
                    {t('files.describe')}
                  </Popover.Body>
                </Popover.Content>
              </Popover>
            </HStack>
          </VStack>
        ) : (
          <Text maxW="300" w="300" isTruncated noOfLines={3}>
            {t('files.instruction')}
          </Text>
        )}
      </HStack>
      <Divider py="0.1" bg="coolGray.200" />
      <VStack my="2">
        <HStack space={3}>
          <Center>
            <Badge
              variant="solid"
              bg="red.400"
              alignSelf="flex-start"
              _text={{
                color: 'coolGray.50',
                fontWeight: 'bold',
                fontSize: 'xs',
              }}
            >
              {t('files.images')}
            </Badge>
          </Center>
          <Button
            fontWeight="bold"
            color="coolGray.800"
            bg="info.500"
            fontSize="md"
            onPress={pickImage}
            leftIcon={<Icon as={Feather} name="upload-cloud" size="sm" />}
          >
            {t('files.uploadImage')}
          </Button>
        </HStack>
        <Box
          p={{ md: '3' }}
          justifyContent="center"
          alignItems={{ md: 'center' }}
        >
          <FlatList
            mb={{ base: 0, md: 20 }}
            mt={{ base: '2' }}
            display={{ md: 'flex' }}
            horizontal={false}
            numColumns={3}
            data={filterManifest(manifest, isImage).contents}
            renderItem={({ item }) => (
              <VStack m={1} borderRadius="md" key={item.filename}>
                <Image
                  borderWidth={1}
                  borderColor="coolGray.200"
                  width="200px"
                  height="200px"
                  rounded="lg"
                  alt="Uploaded image"
                  // @ts-ignore TODO Fix this
                  source={item.data}
                  resizeMode="contain"
                />
                <HStack w="200px" maxWidth="200px" my={3}>
                  <DebouncedTextInput
                    w={{ md: '100%', lg: '100%', base: '100%' }}
                    bg="white"
                    size="lg"
                    color="black"
                    debounceMs={1000}
                    value={stripFileExtension(item.filename)}
                    onChangeText={t =>
                      setManifest(
                        changeFilenameInManifest(manifest, item.sha256, t)
                      )
                    }
                  />
                  <IconButton
                    icon={
                      <Icon as={MaterialCommunityIcons} name="delete-forever" />
                    }
                    borderRadius="full"
                    onPress={() =>
                      setManifest(
                        filterManifest(
                          manifest,
                          e => item.filename !== e.filename
                        )
                      )
                    }
                  />
                </HStack>
              </VStack>
            )}
            keyExtractor={(item, index) => 'key' + index}
          />
        </Box>
      </VStack>
    </VStack>
  )
}
