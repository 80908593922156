import { HStack } from 'native-base'
import React from 'react'
import { FormType } from 'utils/types/form'
// @ts-ignore typescript doesn't do native/web modules
import FormEditorComponent from 'components/FormEditorComponent'
import FormPrinted from 'components/FormPrinted'
import { FormManifestWithData, FormMetadata } from 'utils/types/formMetadata'

export default function FormEditorPrinted({
  formMetadata,
  manifest,
  setManifest,
  setForm,
}: {
  formMetadata: Partial<FormMetadata>
  manifest: FormManifestWithData
  setManifest: any
  setForm: (form: FormType) => any
}) {
  return (
    <HStack justifyContent="center">
      <FormEditorComponent manifest={manifest} setForm={setForm} addendum={false} />
      <FormPrinted
        formMetadata={formMetadata}
        manifest={manifest}
        setManifest={setManifest}
      />
    </HStack>
  )
}
