import React from 'react'
import DashboardLayout from 'components/DashboardLayout'
import UserSearch from 'components/UserSearch'
import { t } from 'i18n-js'

export default function FindUser({ route, navigation }: any) {
  return (
    <DashboardLayout
      navigation={navigation}
      displaySidebar={false}
      displayScreenTitle={false}
      title={t('home.find-user')}
    >
      <UserSearch
        selectItem={(user, setRefresh) => {
          navigation.navigate('EditUser', {
            ...route.params,
            user,
            setRefresh,
          })
        }}
      />
    </DashboardLayout>
  )
}
