import React, { useEffect, useState } from 'react'
import Loading from 'components/Loading'
import { t } from 'i18n-js'
import {findEmail} from "../api/manager";
import {EmailList} from "./EmailList";
import {EmailType} from "../utils/types/email";

export default function EmailSearch({
  selectItem,
}: {
  selectItem: (
    u: EmailType,
    setRefresh: React.Dispatch<React.SetStateAction<Date>>
  ) => any
}) {
  const [nextKey, setNextKey] = useState(undefined as any)
  const [filterVerificationStatus, setFilterVerificationStatus] = useState<string>('')
  const [filterEnabled, setFilterEnabled] = useState<string>('')
  const [filterSearchType, setFilterSearchType] = useState('')
  const [filterText, setFilterText] = useState(undefined as undefined | string)
  const [waiting, setWaiting] = useState(null as null | string)
  const [emails,setEmails] = useState<EmailType[]>([])

  const [refresh, setRefresh] = useState(new Date())

  const doSearch = async () => {
    await findEmail(
        () => setWaiting(t('alert.searching')),
        () => setWaiting(null),
        filterVerificationStatus,
        filterEnabled,
        filterSearchType,
        filterText,
        () => setWaiting(t('alert.loading')),
        setEmails,
        setNextKey
    )
  }

  useEffect(() => {
    doSearch()
  }, [
    filterVerificationStatus,
    filterEnabled,
    filterSearchType,
    filterText,
    refresh,
  ])


  return (
    <>
      <EmailList
        hasMore={false}
        emails={emails}
        filterVerificationStatus={filterVerificationStatus}
        filterEnabled={filterEnabled}
        setFilterVerificationStatus={setFilterVerificationStatus}
        setFilterEnabled={setFilterEnabled}
        filterSearchType={filterSearchType}
        setFilterSearchType={setFilterSearchType}
        filterText={filterText}
        setFilterText={setFilterText}
        selectItem={email => selectItem(email, setRefresh)}
        doSearch={doSearch}
      />
      <Loading loading={waiting} />
    </>
  )
}
