import { MaterialIcons } from '@expo/vector-icons'
import { createLocation, deleteLocation, updateLocation } from 'api/manager'
import { standardHandler } from 'api/utils'
import AnyCountry from 'components/AnyCountry'
import FloatingLabelInput from 'components/FloatingLabelInput'
import Language from 'components/Language'
import Loading from 'components/Loading'
import NecessaryItem from 'components/NecessaryItem'
import { t } from 'i18n-js'
import _ from 'lodash'
import {
  Button,
  CheckIcon,
  CloseIcon,
  HStack,
  Icon,
  Popover,
  Select,
  Tooltip,
  VStack,
  View, Text,
} from 'native-base'
import React, { useState } from 'react'
import { useInfo } from 'utils/errors'
import type { LocationType } from 'utils/types/location'
import { locationEntityTypes } from 'utils/types/location'
import { useLanguage } from './LanguagePicker'

export default function LocationEditor({
  files,
  location,
  setLocation,
  changed,
  reloadPrevious,
}: {
  files: Record<string, any>
  location: Partial<LocationType>
  setLocation: React.Dispatch<React.SetStateAction<Partial<LocationType>>>
  changed: boolean
  reloadPrevious: React.MutableRefObject<boolean>
}) {
  const [error, warning, success] = useInfo()
  const [waiting, setWaiting] = useState(null as null | string)
  const [errors, setErrors] = useState<Partial<Record<keyof LocationType, string>>>({});

  const createMode = !(location.locationUUID && location.locationUUID !== '')

  const standardReporters = { setWaiting, error, warning, success }
  const { language } = useLanguage()



  const validateForm = (metadata: Partial<LocationType> = location) => {
    const newErrors: Partial<Record<keyof LocationType, string>> = {};

    if (!metadata.legalName) {
      newErrors.legalName = t('form-editor.required');
    }

    if (!metadata.shortName) {
      newErrors.shortName = t('form-editor.required');
    }

    if (!metadata.entityType) {
      newErrors.entityType = t('form-editor.required');
    }


    if (!metadata.email) {
      newErrors.email = t('form-editor.required');
    }

    if (!metadata.address) {
      newErrors.address= t('form-editor.required');
    }

    if (!metadata.mailingAddress) {
      newErrors.mailingAddress= t('form-editor.required');
    }

    if (!metadata.country) {
      newErrors.country = t('form-editor.required');
    }

    if (!metadata.phoneNumber) {
      newErrors.phoneNumber = t('form-editor.required');
    }

    if (!metadata.language) {
      newErrors.language = t('form-editor.required');
    }

    if (!metadata.coordinates) {
      newErrors.coordinates = t('form-editor.required');
    }

    setErrors(newErrors);

    return _.isEmpty(newErrors);
  };

  const handleCreateLocation = () => {
    if (!validateForm()) return;
    standardHandler(
        standardReporters,
        'Creating location',
        'Location created',
        async () => {
          setLocation(
              await createLocation(
                  //@ts-ignore We validate this before the call
                  location
              )
          )
          reloadPrevious.current = true
        }
    )
  }

  const submitLocation = (updatedLocation: Partial<LocationType>) => {
    if (!validateForm()) return;
    standardHandler(
        standardReporters,
        'Updating location',
        'Location updated',
        async () => {
          setLocation(
              await updateLocation(
                  //@ts-ignore We validate this before the call
                  updatedLocation
              )
          )
          reloadPrevious.current = true
        }
    )
  }


  const handleDeleteLocation = () =>
    standardHandler(
      standardReporters,
      'Deleting location',
      'Location deleted',
      async () => {
        await deleteLocation(
          //@ts-ignore We validate this before the call
          location
        )
        reloadPrevious.current = true
      }
    )

  const handleSubmitLocation = () => {
    if (!validateForm()) return;
    submitLocation(location)
  }

  const toggleLocation = () => {
    if (!validateForm()) return;
    const newLocation = { ...location, enabled: !location.enabled }
    setLocation(newLocation)
    submitLocation(newLocation)
    reloadPrevious.current = true
  }


  const handleInputChange = (key: keyof LocationType, value: string) => {
    setLocation(prevState => {
      const updatedFormMetadata = { ...prevState, [key]: value };
      validateForm(updatedFormMetadata);
      return updatedFormMetadata;
    });
  };

  return (
    <>
      <VStack>
        <FloatingLabelInput
          label={t('location.full-official-name')}
          value={location.legalName}
          setValue={v => handleInputChange('legalName', v)}
          error={errors.legalName}
          mt={10}
        />
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('form.label.short-name')}
            w="100%"
            containerW="45%"
            value={location.shortName}
            setValue={v => handleInputChange('shortName', v)}
            error={errors.shortName}
          />
          <View position={'relative'}>
            <Select
              size="md"
              selectedValue={location.entityType}
              placeholder={t('location.entity-type')}
              w="95%"
              onValueChange={itemValue => {
                if (itemValue != null) {
                  handleInputChange('entityType', itemValue)
                }
              }}
              borderColor={errors.entityType ? 'red.500' : 'coolGray.200'}
            >
              {locationEntityTypes.map((e, i) => (
                <Select.Item
                  size="md"
                  key={e}
                  label={t('location.entity.' + e)}
                  value={e}
                />
              ))}
            </Select>
            {errors.entityType && (
                <Text color="red.500" mt={1} fontSize="xs" position={'absolute'} left={0} top={10}>
                  {t(`error.${errors.entityType}`)}
                </Text>
            )}
          </View>
        </HStack>
        <FloatingLabelInput
          label={t('location.address')}
          value={location.address}
          setValue={v => handleInputChange('address', v)}
          error={errors.address}
        />
        <FloatingLabelInput
          label={t('location.mailing-address')}
          value={location.mailingAddress}
          setValue={v => handleInputChange('mailingAddress', v)}
          error={errors.mailingAddress}
        />
        <HStack alignItems="center" justifyContent="space-between">
          <AnyCountry
            placeholder={t('location.select-country')}
            value={location.country}
            setValue={v => handleInputChange('country', v)}
            error={errors.country}
            mx={3}
            mt={1}
            mb={3}
          />
          <Language
            placeholder={t('location.select-default-language')}
            value={location.language}
            setValue={v => handleInputChange('language', v)}
            error={errors.language}
            mx={3}
            mt={1}
            mb={3}
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('user.phone-number')}
            w="100%"
            containerW="45%"
            value={location.phoneNumber}
            setValue={v => handleInputChange('phoneNumber', v)}
            error={errors.phoneNumber}
          />
          <FloatingLabelInput
            label={t('user.email')}
            w="100%"
            containerW="45%"
            value={location.email}
            setValue={v => handleInputChange('email', v)}
            error={errors.email}
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('form.label.coordinates')}
            w="100%"
            containerW="80%"
            value={location.coordinates}
            setValue={v => handleInputChange('coordinates', v)}
            error={errors.coordinates}
          />
          <Popover
            trigger={triggerProps => {
              return (
                <Button {...triggerProps} colorScheme="warning" m={4}>
                  {t('other.help')}
                </Button>
              )
            }}
          >
            <Popover.Content
              accessibilityLabel={t('form.how-get-GPS-coordinates')}
              w="56"
            >
              <Popover.Arrow />
              <Popover.CloseButton />
              <Popover.Header>{t('alert.getting-coordinates')}</Popover.Header>
              <Popover.Body>
                {t('location.coordinates-instructions')}
              </Popover.Body>
            </Popover.Content>
          </Popover>
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={t('form.label.tag-optional')}
            w="100%"
            containerW="45%"
            value={location.tags ? _.join(Array.from(location.tags), ' ') : ''}
            setValue={v =>
              setLocation({ ...location, tags: new Set(_.split(v, / |,/)) })
            }
          />
          <View w="45%">
            <NecessaryItem
              isDone={location.enabled || false}
              todoText={t('form.location-is-disabled')}
              doneText={t('form.location-is-enabled')}
              size={4}
            />
          </View>
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            label={
              t('location.id') +
              ' (' +
              t('location.automatically-created') +
              ')'
            }
            w="100%"
            containerW="45%"
            isReadOnly
            placeholder={
              location.locationID
                ? location.locationID
                : t('form.not-yet-created')
            }
          />
          <FloatingLabelInput
            isReadOnly
            label={t('form.created-on')}
            placeholder={
              location.createdDate
                ? location.createdDate.toString()
                : t('form.not-yet-created')
            }
            w="100%"
            containerW="45%"
          />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <FloatingLabelInput
            isReadOnly
            label={t('form.location-version')}
            w="100%"
            containerW="45%"
            placeholder={
              location.version ? location.version : t('form.not-yet-created')
            }
          />
          <FloatingLabelInput
            isReadOnly
            label={t('form.last-changed-on')}
            placeholder={
              location.lastChangedDate
                ? location.lastChangedDate.toString()
                : t('form.not-yet-created')
            }
            w="100%"
            containerW="45%"
          />
        </HStack>
        {createMode ? (
          <HStack mt={5} justifyContent="center">
            <Button
              leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
              colorScheme="green"
              onPress={handleCreateLocation}
            >
              {t('location.create-location')}
            </Button>
          </HStack>
        ) : (
          <HStack mt={5} justifyContent="space-between">
            <Button
              leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
              colorScheme="green"
              onPress={handleSubmitLocation}
            >
              {t('location.submit-location')}
            </Button>
            {false && (
              // TODO We don't allow location deletion anymore
              <Button
                leftIcon={<Icon as={MaterialIcons} name="delete" size="sm" />}
                onPress={handleDeleteLocation}
              >
                {t('location.delete-location')}
              </Button>
            )}
            <Tooltip
              openDelay={0}
              label={t('record.overview.submit-first')}
              isDisabled={!changed}
            >
              <Button
                leftIcon={
                  location.enabled ? (
                    <CloseIcon size={'5'} mx={2} />
                  ) : (
                    <CheckIcon size={'5'} mx={2} />
                  )
                }
                colorScheme={location.enabled ? 'red' : 'green'}
                onPress={toggleLocation}
              >
                {location.enabled
                  ? t('location.disable-location')
                  : t('location.enable-location')}
              </Button>
            </Tooltip>
          </HStack>
        )}
      </VStack>
      <Loading loading={waiting} />
    </>
  )
}
